import React, { useEffect, useState } from "react";
import Sidebars from "../../components/sidebars/Sidebars";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import {
  useGetOrdersQuery,
  useDeleteOrderMutation,
} from "../../Services/OrderApi";
import ReactPaginate from "react-paginate";
import ViewOrderManagement from "./viewOrder/ViewOrderManagement";
import DeleteOrder from "./deleteOrder/DeleteOrder";
import { toast } from "react-toastify";
import LoadingAction from "../../components/Loader";

const OrderManagement = () => {
  const { data, refetch } = useGetOrdersQuery();
  const [deleteCustomer] = useDeleteOrderMutation();
  const [orederConfirmed, setOrderConfirmed] = useState([]);
  const [orederCompleted, setOrderCompleted] = useState([]);
  const [paginated, setPaginated] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [pageCountCompleted, setPageCountCompleted] = useState(0);
  const [search, setSearch] = useState("");
  const [paginatedCompleted, setPaginatedCompleted] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderStatus, setOrderStatus] = useState("Confirmed");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (data) {
      let dataFilterConfirm = data.data.filter(
        (item) => item.status == "Confirmed" && item.transaction_id != null
      );
      let dataFilterComplted = data.data.filter(
        (item) => item.status == "Completed" && item.transaction_id != null
      );
      setOrderConfirmed(dataFilterConfirm);
      setOrderCompleted(dataFilterComplted);
      setPageCount(dataFilterConfirm?.length / itemsPerPage);
      setPageCountCompleted(dataFilterComplted?.length / itemsPerPage);
    }
  }, [data]);

  const handlePageClick = (e) => {
    setPage(e.selected);
    const newOffset = e.selected * itemsPerPage;
    setOffset(newOffset);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(0);
    setPage(0);
  };

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = [];
    if (search?.length > 0) {
      let searchArr = orederConfirmed.filter((item) => {
        const amount = item?.transaction_id?.amount;
        if (!isNaN(search)) {
          return amount?.toString().includes(search.toString());
        } else {
          return (
            item?.doordash_order_id
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?._id?.toLowerCase().includes(search.toLowerCase())
          );
        }
      });

      setPageCount(Math.ceil(searchArr?.length / itemsPerPage));
      paginated = searchArr?.slice(offset, endOffset);
    } else {
      setPageCount(Math.ceil(orederConfirmed?.length / itemsPerPage));
      paginated = orederConfirmed?.slice(offset, endOffset);
    }
    setPaginated(paginated);
  };

  const paginationHandlerCompleted = () => {
    const endOffset = offset + itemsPerPage;
    let paginatedCompleted = [];
    if (search?.length > 0) {
      let searchArr = orederCompleted.filter((item) => {
        const amount = item?.transaction_id?.amount;

        // Check if search is a number
        if (!isNaN(search)) {
          return amount?.toString().includes(search.toString());
        } else {
          // Fallback for string-based searches on other fields
          return (
            item?.doordash_order_id
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?._id?.toLowerCase().includes(search.toLowerCase())
          );
        }
      });

      setPageCountCompleted(Math.ceil(searchArr?.length / itemsPerPage));
      paginatedCompleted = searchArr?.slice(offset, endOffset);
    } else {
      setPageCountCompleted(Math.ceil(orederCompleted?.length / itemsPerPage));
      paginatedCompleted = orederCompleted?.slice(offset, endOffset);
    }
    setPaginatedCompleted(paginatedCompleted);
  };

  useEffect(() => {
    paginationHandler();
    paginationHandlerCompleted();
  }, [orederConfirmed, orederCompleted, offset, search]);

  const handleClose = () => {
    setShowViewModal(false);
    setShowDeleteModal(false);
  };

  const viewHandler = (id) => {
    setOrderId(id);
    setShowViewModal(true);
  };

  const deleteHandler = (id) => {
    setOrderId(id);
    setShowDeleteModal(true);
  };

  const handlerNavigate = (status) => {
    setOrderStatus(status);
  };

  const handleDeletePass = () => {
    setShowLoader(true);
    deleteCustomer(orderId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setShowLoader(false);
        setOrderConfirmed(
          orederConfirmed.filter((customer) => customer?.id !== orderId)
        );
        setOrderCompleted(
          orederCompleted.filter((customer) => customer?.id !== orderId)
        );
        refetch();
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  return (
    <>
      <Sidebars />
      <Header />
      <main id="main" class="main mt-0 h-100 min-vh-80">
        <div class="pagetitle">
          <h1>Order Management</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li class="breadcrumb-item active">Order Management</li>
            </ol>
          </nav>
        </div>
        <div class="container-fluid mt-4 pt-2">
          <div class="row">
            <div class="col-md-12 px-0">
              <h6 class="text-color fw-bolder mb-3">Order Lists</h6>
              <div class="clearfix mt-2">
                <div class="float-start">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => handlerNavigate("Confirmed")}
                      >
                        Confirmed Orders
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={() => handlerNavigate("Completed")}
                      >
                        Completed Orders
                      </button>
                    </li>
                    {/* <li class="nav-item" role="presentation">
                                            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Completed Orders</button>
                                        </li> */}
                  </ul>
                </div>
              </div>
              {showViewModal && (
                <ViewOrderManagement
                  showViewModal={showViewModal}
                  handleClose={handleClose}
                  orderId={orderId}
                />
              )}
              {showDeleteModal && (
                <DeleteOrder
                  showDeleteModal={showDeleteModal}
                  handleClose={handleClose}
                  handleDelete={handleDeletePass}
                />
              )}

              {showLoader && <LoadingAction />}
              <div class="clearfix mb-4 mt-2">
                <div class="float-start">
                  <div class="search-bar mt-1">
                    <form class="d-flex" role="search">
                      <div class="border-rounded-15">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control border-0 bg-none"
                            placeholder="Search..."
                            onChange={handleSearch}
                          />
                          <span class="input-group-text bg-none border-0">
                            <i class="b- bi-search"></i>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="float-end"></div>
              </div>

              <div class="col-md-12">
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabindex="0"
                  >
                    <div class="card border-0 table-card">
                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-striped mb-0 align-middle common-table ">
                            <thead>
                              <tr>
                                <th scope="col">S no.</th>
                                <th scope="col">Order ID</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Order Type</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paginated &&
                                paginated.map((order, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      #
                                      {order?.doordash_order_id
                                        ? order?.doordash_order_id
                                        : order._id}
                                    </td>
                                    <td>${order?.transaction_id?.amount}</td>
                                    <td>{order.order_type}</td>
                                    <td>{order.createdAt.split("T")[0]}</td>
                                    <td>
                                      {" "}
                                      <span class="status-active">
                                        {order.status}
                                      </span>
                                    </td>
                                    <td>
                                      <button
                                        class="btn btn-view  me-2 action-wh"
                                        onClick={() => viewHandler(order._id)}
                                      >
                                        <img
                                          src="assets/img/view.svg"
                                          alt="image"
                                          class="img-fluid action-image"
                                        />
                                      </button>
                                      <button
                                        class="btn btn-delete  me-2 action-wh"
                                        onClick={() => deleteHandler(order._id)}
                                      >
                                        <img
                                          src="assets/img/delete.svg"
                                          alt="image"
                                          class="img-fluid action-image"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabindex="0"
                  >
                    <div class="card border-0 table-card">
                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-striped mb-0 align-middle common-table ">
                            <thead>
                              <tr>
                                <th scope="col">S no.</th>
                                <th scope="col">Order ID</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Order Type</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paginatedCompleted &&
                                paginatedCompleted.map((order, index) => (
                                  <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>
                                      #
                                      {order?.doordash_order_id
                                        ? order?.doordash_order_id
                                        : order._id}
                                    </td>
                                    <td>${order?.transaction_id?.amount}</td>
                                    <td>
                                      {order.order_type
                                        ? order.order_type
                                        : "NIL"}
                                    </td>
                                    <td>{order.createdAt.split("T")[0]}</td>
                                    <td>
                                      {" "}
                                      <span class="status-active">
                                        {order.status}
                                      </span>
                                    </td>
                                    <td>
                                      <button class="btn btn-view  me-2 action-wh">
                                        <img
                                          src="assets/img/view.svg"
                                          alt="image"
                                          class="img-fluid action-image"
                                          onClick={() => viewHandler(order._id)}
                                        />
                                      </button>
                                      <button class="btn btn-delete  me-2 action-wh">
                                        <img
                                          src="assets/img/delete.svg"
                                          alt="image"
                                          class="img-fluid action-image"
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {(orederConfirmed?.length > 10 || orederCompleted?.length > 10) && (
        <ReactPaginate
          breakLabel="..."
          breakClassName={"break"}
          nextLabel="next >"
          containerClassName={"pagination"}
          activeClassName={"active"}
          onPageChange={handlePageClick}
          forcePage={page}
          pageRangeDisplayed={5}
          pageCount={
            orderStatus === "Confirmed" ? pageCount : pageCountCompleted
          }
          previousLabel="< previous"
        />
      )}
      <Footer />
    </>
  );
};
//
export default OrderManagement;
