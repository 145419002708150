import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logoColor from "../../assets/logo-color.svg"
import { toast } from 'react-toastify';
import {useForgotVerificationPostMutation} from "../../Services/ForgotPasswordApi"

const OtpVerification = () => {
   const navigate = useNavigate();
   const [addVerificationCode] = useForgotVerificationPostMutation();
   const [verifyCode, setVerifyCode] = useState('');

   const verifyHandler = (e) => {
      e.preventDefault();
    
      if (verifyCode.length !== 4) {
         return toast.error("Verification code should be 4 digits")
      } 
      let user = localStorage.getItem('emailAdmin');
      let verificationDetail = {
         email: user,
         otp: verifyCode
       }
       addVerificationCode(verificationDetail)
         .unwrap()
         .then((res) => {
           toast.success(res?.message);
           if (res?.success) {
            navigate('/reset-password')
           }
         })
         .catch((err) => {
           toast.error(err?.data?.message);
         });

   }
   return (
      <div className="bg-fruit">
         <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100 h-100">
               <div className="col-md-8 col-lg-6 col-xl-5">
                  <div className="card border-0">
                     <div className="card-body p-md-5 p-4">
                        <div className="">
                           <img src={logoColor} alt="logo" className="img-fluid mb-4 mx-auto d-block" width="220" />
                           <h6 className="text-uppercase fw-bolder mb-1 pt-4 text-color">Verfication Code</h6>
                           <p className="mb-4 small">We’ll send you a verification code.</p>
                        </div>
                        <form action="" className="form-login n">
                           <div className="mb-4">
                              <label for="code" className="form-label small fw-semibold text-black">Code</label>
                              <input type="number"
                                 className="form-control"
                                 placeholder="Enter Verification Code"
                                 aria-label="code" aria-describedby="code"
                                 value={verifyCode}
                                 onChange={(e) => {
                                    const code = e.target.value;
                                    // Ensure the code consists of only digits and has a length of 4
                                    if (/^\d{0,4}$/.test(code)) {
                                       setVerifyCode(code);
                                    }
                                 }}
                                 maxLength={4}
                              />
                           </div>
                           <button
                              type="submit"
                              onClick={verifyHandler}
                              className="btn bg-button w-100 text-white py-3 mt-3">Verify</button>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default OtpVerification