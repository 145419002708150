import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import LoadingAction from '../../../components/Loader';
import { useCreateCustomerSellerPostMutation } from '../../../Services/ManageCustomerRetailerApi';


const buttonWidth = {
    width: '100%'
};

const AddCustomer = (props) => {
    const { show, handleClose,onCustomerAdded } = props;
    const [showLoader, setShowLoader] = useState(false);
    const [addCustomer] = useCreateCustomerSellerPostMutation();
    const [inputField, setInputField] = useState({
        email: '',
        password: '',
        fullName: '',
        mobileNumber: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        let inputValue = value;
        if (name === 'mobileNumber') {
            inputValue = value.replace(/\D/g, '');
            if (inputValue.length > 13) {
                inputValue = inputValue.slice(0, 13);
            }
        }
        setInputField(prevState => ({
            ...prevState,
            [name]: inputValue
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowLoader(true)
        inputField["role"]="user"
        addCustomer(inputField)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                onCustomerAdded(res.data);
                handleClose();
                setShowLoader(false)
            })
            .catch((err) => {
                setShowLoader(false)
                toast.error(err?.data?.message);
            });
    }


    return (
        <Modal show={show} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className='h4 '>Add Customer</Modal.Title>
                </div>
            </Modal.Header>
            {showLoader && <LoadingAction />}
            <Modal.Body className='px-md-5'>
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        <input type="text"
                            name="fullName"
                            value={inputField?.fullName}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter  Name" />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        <input type="email"
                            name="email"
                            value={inputField?.email}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter Email" />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Password</label>
                        <input type="password"
                            name="password"
                            value={inputField?.password}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter Email" />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Mobile Number</label>
                        <input type="text"
                            name="mobileNumber"
                            value={inputField?.mobileNumber}
                            onChange={handleChange}
                            className="form-control"
                            placeholder="Enter  Number" />
                    </div>
                    <div className="text-center mb-4">
                        <button
                            type="submit"
                            className="btn bg-button py-3 text-white"
                            style={buttonWidth}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddCustomer