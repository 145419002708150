import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  useGetSingleCharityQuery,
  useEditCharityMutation,
} from "../../../Services/CharityApi";
import LoadingAction from "../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const EditCharity = (props) => {
  const { showEdit, handleClose, charityId } = props;
  const { data } = useGetSingleCharityQuery(charityId);
  const [editCharity] = useEditCharityMutation();
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setName(data?.data?.name);
    console.log("data-->", data);
  }, [data, charityId]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    setShowLoader(true);
    editCharity({ id: charityId, data: formData })
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        handleClose();
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={showEdit} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h4 ">Edit Charity</Modal.Title>
        </div>
      </Modal.Header>
      {showLoader && <LoadingAction />}
      <Modal.Body className="px-md-5">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter  Name"
              value={name}
              onChange={handleNameChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Image</label>
            {/* <input type="file" className="form-control" onChange={handleImageChange} /> */}

            <label
              for="file-input"
              id="custom-button"
              className="flex-column-reverse justify-content-space-evenly"
            >
              <span id="file-name">
                {image?.name?.length > 0 ? image?.name : "No File chosen"}
              </span>
            </label>

            <input type="file" id="file-input" onChange={handleImageChange} />
          </div>

          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn bg-button py-3 text-white"
              style={buttonWidth}
            >
              Update
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCharity;
