
export const isLoggedIn = () => {
    let data = localStorage.getItem('tokenAdmin');
    if (data != null) return true;
    else return false;
}

export const doLogin = (token,fullName,email) => {
    localStorage.setItem('tokenAdmin', token);
    localStorage.setItem('fullNameAdmin', fullName);
    localStorage.setItem('emailAdmin', email);
    //next()
}

export const doLogout = (next) => {
    localStorage.removeItem('fullNameAdmin');
    localStorage.removeItem('emailAdmin');
    localStorage.removeItem('tokenAdmin')
    //next()
}
