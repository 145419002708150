import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useGetCustomerRetailerQuery, useEditCustomerSellerMutation } from '../../../../Services/ManageCustomerRetailerApi';
import { toast } from "react-toastify";
import LoadingAction from '../../../../components/Loader';
const buttonWidth = {
    width: '100%'
};

const EditRetailerList = (props) => {
    const { showEdit, handleClose, retailerId } = props;
    const { data } = useGetCustomerRetailerQuery(retailerId);
    const [editRetailer] = useEditCustomerSellerMutation();
    const [showLoader, setShowLoader] = useState(false);
    const [inputField, setInputField] = useState({
        email: '',
        fullName: '',
        mobileNumber: ''
    })

    useEffect(() => {
        setInputField(data?.data)
    }, [data, retailerId])

    const handleChange = (e) => {
        const { name, value } = e.target;
        let inputValue = value;
        if (name === 'mobileNumber') {
            inputValue = value.replace(/\D/g, '');
            if (inputValue.length > 13) {
                inputValue = inputValue.slice(0, 13);
            }
        }
        setInputField(prevState => ({
            ...prevState,
            [name]: inputValue
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowLoader(true);
        editRetailer({ id: retailerId, data: inputField })
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                handleClose();
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                toast.error(err?.data?.message);
            });
    }



    return (
        <Modal show={showEdit} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className='h4 '>Edit Customer</Modal.Title>
                </div>
            </Modal.Header>
            {showLoader && <LoadingAction />}
            <Modal.Body className='px-md-5'>
                <form onSubmit={handleSubmit} className="form-login n">

                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        <input type="text"
                            name="fullName"
                            value={inputField?.fullName}
                            onChange={handleChange}
                            className="form-control" placeholder="Enter  Name" />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        <input type="email"
                            name="email"
                            value={inputField?.email}
                            onChange={handleChange}
                            className="form-control" placeholder="Enter Email" />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Mobile Number</label>
                        <input type="number"
                            name="mobileNumber"
                            value={inputField?.mobileNumber}
                            onChange={handleChange}
                            className="form-control" placeholder="Enter  Number" />
                    </div>
                    <div className="text-center mb-4">
                        <button
                            type="submit"
                            className="btn bg-button py-3 text-white"
                            style={buttonWidth}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditRetailerList