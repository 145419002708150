import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebars from '../../components/sidebars/Sidebars'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer';
import { useGetFaqQuery,useDeleteFaqMutation } from '../../Services/FaqApi';
import EditFaq from './editFaq/EditFaq';
import DeleteFaq from './deleteFaq/DeleteFaq';
import { toast } from "react-toastify";
import LoadingAction from '../../components/Loader';


const Faq = () => {
    const navigate = useNavigate();
    const { data: faqRetrive,refetch } = useGetFaqQuery();
    const [faqs, setFaqs] = useState([' ']);
    const [deleteFaq] = useDeleteFaqMutation();
    const [editShowModal, setEditShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [faqId,setFaqId] = useState('')
    const [showLoader, setShowLoader] = useState(false);

    const addQuestionHandler = () =>{
        navigate('/add-faq')
    }
    useEffect(()=>{
        setFaqs(faqRetrive?.data)
    },[faqRetrive])
    const handleClose = () => {
        setEditShowModal(false);
        setShowDeleteModal(false)
      };
    const editFaqHandler = (e,id)=>{
        e.preventDefault();
        setEditShowModal(true);
        setFaqId(id);
    }
    const deleteFaqHandler=(e,id)=>{
        e.preventDefault();
        setShowDeleteModal(true);
        setFaqId(id);
        
    }
    const handleDeleteFaq = () => {
        setShowLoader(true)
        deleteFaq(faqId)
          .unwrap()
          .then((res) => {
            toast.success(res?.message);
            setShowLoader(false)
            setFaqs(
                faqs.filter((customer) => customer?.id !== faqId)
            ); 
            refetch();
          })
          .catch((err) => {
            setShowLoader(false)
            toast.error(err?.data?.message);
          });
      };

    return (
     <>
     <Sidebars />
     <Header />
        <main id="main" className="main mt-0 h-100 min-vh-80">
            <div className="pagetitle">
                <h1>Faq's</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="">Home</a></li>
                        <li className="breadcrumb-item active">Faq's</li>
                    </ol>
                </nav>
            </div>
             {editShowModal && (<EditFaq editShowModal={editShowModal} handleClose={handleClose} faqId={faqId}/>)}
             {showDeleteModal && (<DeleteFaq showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteFaq}/>)} 
            <div className="container-fluid mt-4 pt-2">
                <div className="row">
                    <div className="col-md-12 px-0">
                    {showLoader && <LoadingAction />}
                        <h6 className="text-color fw-bolder mb-3">Faq Lists</h6>

                        <div className="clearfix mb-4 mt-2">
                            <div className="float-start">
                                <div className="search-bar mt-1">
                                    <form className="d-flex" role="search">
                                        <div className="border-rounded-15">
                                            <div className="input-group">
                                                <input type="text" className="form-control border-0 bg-none" placeholder="Search..." />
                                                <span className="input-group-text bg-none border-0">
                                                    <i className="b- bi-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="float-end">
                                <button className="btn btn-main me-2" onClick={addQuestionHandler}><i className="bi bi-plus-lg me-2"></i>Add Questions</button>
                            </div>
                        </div>

                        <div className="card border-0 table-card">
                            <div className="card-body">

                                <div className="table-responsive">
                                    <table className="table table-striped mb-0 align-middle common-table ">
                                        <thead>
                                            <tr>
                                                <th scope="col">S no.</th>
                                                <th scope="col">Question</th>
                                                <th scope="col">Answer</th>
                                                {/* <th scope="col">Status</th> */}
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {faqs?.map((faq, index) => (
                                                    <tr key={faq.id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{faq.question}</td>
                                                        <td>{faq?.answer?.substring(0,30)+ '...'}</td>
                                                        <td>
                                                            {/* Add buttons for actions */}
                                                            <button className="btn btn-edit me-2 action-wh" onClick={(e)=>editFaqHandler(e,faq?._id)}><img src="assets/img/edit.svg" alt="image" className="img-fluid action-image" /></button>
                                                            <button className="btn btn-delete  me-2 action-wh" onClick={(e)=>deleteFaqHandler(e,faq?._id)}><img src="assets/img/delete.svg" alt="image" className="img-fluid action-image" /></button>
                                                        </td>
                                                    </tr>
                                                ))}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
     </>
    )
}

export default Faq