import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import logoColor from "../../assets/logo-color.svg"
import { FormValidation, FormValidationOnChange } from "./ResetPassowrdValidation"
import { toast } from "react-toastify";
import { useCreateNewPasswordMutation } from "../../Services/ForgotPasswordApi"

const ResetPassword = () => {
   const navigate = useNavigate();
   const [createNewPass] = useCreateNewPasswordMutation();

   const [inputField, setInputField] = useState({
      password: "",
      confirmPassword: ''
   });
   const [errField, setErrField] = useState({
      passwordErr: "",
      confirmPasswordErr: ''
   });

   const saveNewPasswordHandler = (e) => {
      e.preventDefault();

      if (FormValidation({ inputField, setErrField })) {
         if (inputField?.password.trim() !== inputField?.confirmPassword.trim()) {
            return toast.error("Password and confirmed password do not match")
         }
         let user = localStorage.getItem('emailAdmin')
         const inputData = {
            email: user,
            password: inputField?.password
         }
         createNewPass(inputData)
            .unwrap()
            .then((res) => {
               toast.success(res?.message);
               if (res?.success) {
                  navigate('/login');
               }
            })
            .catch((err) => {
               toast.error(err?.data?.message);
            });
      }
   }

   const inputChangeHandler = (e) => {
      setInputField({ ...inputField, [e.target.name]: e.target.value });
      let inputName = e.target.name
      let inputValue = e?.target?.value
      FormValidationOnChange({ inputValue, setErrField, inputName })
   }

   return (
      <div className="bg-fruit">
         <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100 h-100">
               <div className="col-md-5">
                  <div className="card border-0">
                     <div className="card-body p-md-5 p-4">
                        <div className="">
                           <img src={logoColor} alt="logo" className="img-fluid mb-4 mx-auto d-block" width="220" />
                           <h6 className="text-uppercase fw-bolder mb-1 pt-4 text-color">Reset Password</h6>
                           <p className="mb-4 small">Your new password must be different from previously
                              used passwords.
                           </p>
                        </div>
                        <form action="" className="form-login n">
                           <div className="mb-4">
                              <label for="password" className="form-label small fw-semibold text-black">Password</label>
                              <input type="password"
                                 className="form-control"
                                 placeholder="Enter Password" aria-label="password" aria-describedby="password"
                                 name="password"
                                 value={inputField?.password}
                                 onChange={inputChangeHandler}
                              />
                              {errField?.passwordErr?.length > 0 && (
                                 <span className="error">{errField?.passwordErr}</span>
                              )}
                           </div>
                           <div className="mb-4">
                              <label for="confirm-password" className="form-label small fw-semibold text-black">Confirm Password</label>
                              <input type="password"
                                 className="form-control" placeholder="Enter Confirm Password"
                                 aria-label="confirm-password" aria-describedby="confirm-password"
                                 name="confirmPassword"
                                 value={inputField?.confirmPassword}
                                 onChange={inputChangeHandler}
                              />
                              {errField?.confirmPasswordErr?.length > 0 && (
                                 <span className="error">{errField?.confirmPasswordErr}</span>
                              )}
                           </div>
                           <button
                              type="submit"
                              onClick={saveNewPasswordHandler}
                              className="btn bg-button w-100 text-white py-3 mt-3"
                           >Save New Password</button>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ResetPassword