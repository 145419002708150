import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
    useGetSinglePromoCodeQuery,
    useEditPromoCodeMutation,
} from "../../../Services/PromoCodeApi";
import LoadingAction from "../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const EditPromoCode = (props) => {
  const { showEdit, handleClose, charityId,onCharityEdited } = props;
  const { data } = useGetSinglePromoCodeQuery(charityId);
  const [editPromoCode] = useEditPromoCodeMutation();
  const [inputField, setInputField] = useState({
    code:"",
    discount:""
  });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setInputField(data?.data)
  }, [data, charityId]);

  const handleNameChange = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);
    editPromoCode({ id: charityId, data: inputField })
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        onCharityEdited(res)
        handleClose();
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={showEdit} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h4 ">Edit Promo Code</Modal.Title>
        </div>
      </Modal.Header>
      {showLoader && <LoadingAction />}
      <Modal.Body className="px-md-5">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Promo Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Promo Code"
              name="code"
              value={inputField?.code}
              onChange={handleNameChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Discount</label>
            {/* <input type="file" className="form-control" onChange={handleImageChange} /> */}
            <input
              type="text"
              className="form-control"
              placeholder="Enter Discount"
              name="discount"
              value={inputField?.discount}
              onChange={handleNameChange}
            />
           
          </div>

          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn bg-button py-3 text-white"
              style={buttonWidth}
            >
              Update
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};


export default EditPromoCode