import React, { useEffect, useState } from "react";
import Sidebars from "../../components/sidebars/Sidebars";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import deleteLogo from "../../assets/delete.svg";
import editLogo from "../../assets/edit.svg";
import DeletePromoCode from "./deletePromoCode/DeletePromoCode";
import EditPromoCode from "./editPromoCode/EditPromoCode";
import AddPromoCode from "./addPromoCode/AddPromoCode";
import {
    useGetPromoCodeListQuery,
    useDeletePromoCodeMutation,
} from "../../Services/PromoCodeApi";
import { GetUrl } from "../../config/GetUrl";
import { toast } from "react-toastify";
import LoadingAction from "../../components/Loader";

const PromoCode = () => {
    const [show, setShow] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { data: retrievePromoCode, isLoading, isFetching, refetch } = useGetPromoCodeListQuery({ searchCode: searchValue });
    const [deletePromoCode] = useDeletePromoCodeMutation();
    const [showEdit, setShowEdit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [charities, setCharities] = useState([""]);
    const [charityId, setCharityId] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [promoCodListUpdated, setPromoCodListUpdated] = useState("");

    useEffect(() => {
        if (promoCodListUpdated.message === "Promo Code has been updated" || promoCodListUpdated.message === "Promo code has been deleted") {
            refetch();
        }
    }, [promoCodListUpdated, charities, isFetching])


    useEffect(() => {
        setCharities(retrievePromoCode?.data);
    }, [retrievePromoCode]);
    const handleClose = () => {
        setShow(false);
        setShowEdit(false);
        setShowDeleteModal(false);
    };
    const handleAddCharity = (newCategory) => {
        setCharities([...charities, newCategory]);
    };
    const handleEditPromoCode = (newCategory) => {
        setPromoCodListUpdated(newCategory)
    };
    const editeHandlerShow = (e, id) => {
        e.preventDefault();
        setShowEdit(true);
        setCharityId(id);
    };
    const deleteShowHandler = (e, id) => {
        e.preventDefault();
        setShowDeleteModal(true);
        setCharityId(id);
    };

    const handleDeleteCharity = () => {
        setShowLoader(true);
        deletePromoCode(charityId)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                setPromoCodListUpdated(res)
                setShowLoader(false);
                // setCharities(charities.filter((category) => category.id !== charityId));
                refetch();
            })
            .catch((err) => {
                setShowLoader(false);
                toast.error(err?.data?.message);
            });
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };
    return (
        <>
            <Sidebars />
            <Header />
            <main id="main" class="main mt-0 h-100 min-vh-80">
                <div class="pagetitle">
                    <h1>Manage Promo Code</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a href="">Home</a>
                            </li>
                            <li class="breadcrumb-item active">Manage Prmo Code</li>
                        </ol>
                    </nav>
                </div>
                {show && (
                    <AddPromoCode
                        show={show}
                        handleClose={handleClose}
                        onCharityAdded={handleAddCharity}
                    />
                )}
                {showEdit && (
                    <EditPromoCode
                        showEdit={showEdit}
                        handleClose={handleClose}
                        charityId={charityId}
                        onCharityEdited={handleEditPromoCode}
                    />
                )}
                {showDeleteModal && (
                    <DeletePromoCode
                        showDeleteModal={showDeleteModal}
                        handleClose={handleClose}
                        handleDelete={handleDeleteCharity}
                    />
                )}

                <div class="container-fluid mt-4 pt-2">
                    <div class="row">
                        <div class="col-md-12 px-0">
                            <h6 class="text-color fw-bolder mb-3">Promo Code Lists</h6>
                            {showLoader && <LoadingAction />}
                            <div class="clearfix mb-4 mt-2">
                                <div class="float-start">
                                    <div class="search-bar mt-1">
                                        <form class="d-flex" role="search">
                                            <div class="border-rounded-15">
                                                <div class="input-group">
                                                    <input
                                                        type="text"
                                                        class="form-control border-0 bg-none"
                                                        placeholder="Search..."
                                                        value={searchValue}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span class="input-group-text bg-none border-0">
                                                        <i class="b- bi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="float-end">
                                    <button
                                        class="btn btn-main me-2"
                                        onClick={(e) => setShow(true)}
                                    >
                                        <i class="bi bi-plus-lg me-2"></i>Add Promo Code
                                    </button>
                                </div>
                            </div>

                            <div class="card border-0 table-card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped mb-0 align-middle common-table ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S no.</th>
                                                    <th scope="col">Promo Code</th>
                                                    <th scope="col">Discount</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {charities?.map((charity, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{charity?.code}</td>
                                                        <td>
                                                            {charity?.discount}
                                                        </td>
                                                        <td>
                                                            <button
                                                                class="btn btn-edit me-2 action-wh"
                                                                onClick={(e) =>
                                                                    editeHandlerShow(e, charity?._id)
                                                                }
                                                            >
                                                                <img
                                                                    src={editLogo}
                                                                    alt="image"
                                                                    class="img-fluid action-image"
                                                                />
                                                            </button>
                                                            <button
                                                                class="btn btn-delete  me-2 action-wh"
                                                                onClick={(e) =>
                                                                    deleteShowHandler(e, charity?._id)
                                                                }
                                                            >
                                                                <img
                                                                    src={deleteLogo}
                                                                    alt="image"
                                                                    class="img-fluid action-image"
                                                                />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                {isLoading && (
                                                    <div className="load-body">Loading...</div>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};



export default PromoCode