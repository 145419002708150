import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useGetCustomerRetailerQuery } from '../../../../Services/ManageCustomerRetailerApi';
import { GetUrl } from '../../../../config/GetUrl';

const buttonWidth = {
    width: '100%'
};

const ViewRetailerList = (props) => {
    const { showViewModal, handleClose,retailerId } = props;
    const { data } = useGetCustomerRetailerQuery(retailerId);
    const [singleCustomer,setSignleCustomer] = useState('')
    const [productList,setProductList] = useState([])

    const handleSubmit = () => {
        handleClose();
    }
    useEffect(()=>{
     setSignleCustomer(data?.data.user)
     setProductList(data?.data.product)
     console.log("data===>",data)
    },[data,retailerId])

    return (
        <Modal show={showViewModal} onHide={handleClose} centered size="lg"  scrollable
        className="scroll-modal">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className='h4'>View Retailer</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className='px-md-5'>
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label">Name</label>
                            <p className='data-p-tag'>{singleCustomer?.fullName}</p>
                        </div>
                        <div className="col">
                            <label className="form-label">Email</label>
                            <p className='data-p-tag'>{singleCustomer?.email}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label">Mobile Number</label>
                            <p className='data-p-tag'>{singleCustomer?.mobileNumber}</p>
                        </div>
                        <div className="col">
                            <label className="form-label">Registration Date</label>
                            <p className='data-p-tag'>{singleCustomer?.createdAt?.substring(0, 10)}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <label className="form-label">Status</label>
                            <p className='data-p-tag'>Inactive</p>
                        </div>
                    </div>

                   
                </form>
                <div class="card border-0 table-card">
                        <div class="card-body">

                           <div class="table-responsive">
                              <table class="table table-striped mb-0 align-middle common-table ">
                                 <thead>
                                    <tr>
                                       <th scope="col">S no.</th>
                                       <th scope="col">Image</th>
                                       <th scope="col">Product Name</th>
                                       <th scope="col">Price</th>
                                       <th scope="col">Status</th>
                                       <th scope="col">Created Date</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                   {productList?.length>0 && productList?.map((product,index)=>
                                   <tr key={index}>
                                   <th scope="row">{index+1}</th>
                                   <td><img src={GetUrl?.IMAGE_URL+product?.images} alt="" width={75}/></td>
                                   <td>{product?.productName}</td>
                                   <td>${product?.itemPrice}</td>                                   
                                   <td><span className={product?.status=='active'?"status-active":"status"}>{product?.status=='active'?"Active":"Inactive"}</span></td>
                                   <td>{product?.createdAt?product?.createdAt?.split("T")[0]:"NIL"}</td>
                                                                   
                                </tr>
                                )}
                                       
                                       {/* <tr >
                                          <th scope="row">1</th><span className={retailer.is_deactivated?"status-active":"status"}>{retailer.is_deactivated?"Active":"Deactivated"}
                                          <td><img src="https://picsum.photos/23" alt="" width={75}/></td>
                                          <td>Grapes</td>
                                          <td>$526</td>
                                          <td>Inactive</td>
                                          <td>2024-08-09</td>
                                                                          
                                       </tr> */}
                                                                  
                                 </tbody>
                              </table>
                              {productList?.length == 0 && <span className="no-data my-4 fw-bold"><p>No Data Found</p></span>}
                           </div>
                        </div>
                     </div>

            </Modal.Body>
            <Modal.Footer className='w-100 d-block px-md-5'>
            <div className="text-center">
                        <button
                            type="submit"
                            className="btn bg-button py-3 text-white w-100"
                            style={buttonWidth}
                        >
                            Close
                        </button>
                    </div>
            </Modal.Footer>
            
        </Modal>
    );
};

export default ViewRetailerList