import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";


export const manageCustomerRetailerApi = createApi({
  reducerPath: "manageCustomerRetailerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    CreateCustomerSellerPost: builder.mutation({
      query: (data) => ({
        url: "/admin/add-customer-retailer",
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    getCustomerRetailer: builder.query({
      query: (id) => ({
        url: `/admin/get-single-customer-seller/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },

      }),
    }),
    EditCustomerSeller: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/edit-customer-seller/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    DeleteCustomerSeller: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-customer-seller/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    deactivateRetailerAccount: builder.mutation({
      query: (id) => ({
        url: `/admin/retailer-deactivate/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),

  }),
});

export const {
  useCreateCustomerSellerPostMutation,
  useGetCustomerRetailerQuery,
  useEditCustomerSellerMutation,
  useDeleteCustomerSellerMutation,
  useDeactivateRetailerAccountMutation
} = manageCustomerRetailerApi;
