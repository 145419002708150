import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  useGetSingleCategoryQuery,
  useEditCategoryMutation,
} from "../../../../Services/CategoryApi";
import { toast } from "react-toastify";
import LoadingAction from "../../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const EditCategory = (props) => {
  const { showEdit, handleClose, categoryId, onCategoryAdded } = props;
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const { data, isLoading, isError } = useGetSingleCategoryQuery(categoryId);
  const [editCategory] = useEditCategoryMutation();
  const [image, setImage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // Fetch data when component mounts or categoryId changes
    setCategoryName(data?.data?.categoryName);
    setDescription(data?.data?.description);
  }, [data, categoryId]);

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);
    const formData = new FormData();
    formData.append("categoryName", categoryName);
    formData.append("description", description);
    formData.append("image", image);
    editCategory({ id: categoryId, data: formData })
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        handleClose();
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  // if (isLoading) return <div>Loading...</div>;
  // if (isError) return <div>Error occurred</div>;
  return (
    <Modal show={showEdit} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">Edit Category</Modal.Title>
        </div>
      </Modal.Header>
      {showLoader && <LoadingAction />}
      <Modal.Body className="px-md-5">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Category Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Category Name"
              value={categoryName}
              onChange={handleCategoryNameChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              placeholder="Enter Description"
              value={description}
              onChange={handleDescriptionChange}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="form-label">Image</label>
            {/* <input type="file" className="form-control" onChange={handleImageChange} /> */}

            <label
              for="file-input"
              id="custom-button"
              className="flex-column-reverse justify-content-space-evenly"
            >
              <span id="file-name">
                {image?.name?.length > 0 ? image?.name : "No File chosen"}
              </span>
            </label>

            <input type="file" id="file-input" onChange={handleImageChange} />
          </div>
          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn bg-button py-3 text-white"
              style={buttonWidth}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCategory;
