import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

const DeactivateRetailer = (props) => {
    const { showDeactivateModal, handleClose,onPassHAndler,activated } = props;

    const handleSubmit= ()=>{
        handleClose()
        onPassHAndler()
    }

    return (
        <Modal show={showDeactivateModal} onHide={handleClose} centered>
             <Modal.Header closeButton={false} className="border-0 mt-4 pt-0 pb-2">
                {/* <div className="d-flex justify-content-center w-100">
                    <Image src={deactivateIcon} width='50' height='50' rounded />
                </div> */}
            </Modal.Header>
            <Modal.Body className='text-center px-md-5 py-2 '>
            <h4 className='text-red-sure'>Are you sure you want to {activated?"Deactivate":"Activate"} <br /> this retailer?</h4>
            </Modal.Body>
            <Modal.Footer className='text-center border-0 d-flex justify-content-around w-75 mx-auto mb-4 pb-0 pt-1'>
                <button type="submit"  onClick={handleSubmit} className=' px-md-5 btn bg-button py-2 text-white' >
                    Yes
                </button>
                <Button variant="secondary" onClick={handleClose} className='btn btn-closed px-md-5 py-2'>
                No
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default DeactivateRetailer

