import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useCreateSubCategoryPostMutation } from "../../../../Services/SubCategoryApi";
import { useGetCategoryQuery } from "../../../../Services/CategoryApi";
import { toast } from "react-toastify";
import LoadingAction from "../../../../components/Loader";
const buttonWidth = {
  width: "80%",
};

const AddSubCategory = (props) => {
  const { show, handleClose, onSubCategoryAdded } = props;
  const [addSubCategory] = useCreateSubCategoryPostMutation();
  const { data: retrieveCategory } = useGetCategoryQuery();
  const [subCategoryName, setSubCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([""]);
  const [category, setCategory] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setCategories(retrieveCategory?.data);
  }, [retrieveCategory]);

  const handleCategoryNameChange = (e) => {
    setSubCategoryName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the form data, for example, submit it to a server
    const formData = new FormData();
    formData.append("category", category);
    formData.append("subCategoryName", subCategoryName);
    formData.append("description", description);
    formData.append("image", image);
    setShowLoader(true)
    addSubCategory(formData)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        onSubCategoryAdded(res.data);
        handleClose();
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h4 ">Add Sub Category</Modal.Title>
        </div>
      </Modal.Header>
      {showLoader && <LoadingAction />}
      <Modal.Body className="px-md-5">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Category</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={category}
              onChange={handleCategoryChange}
            >
              <option value="">Select</option>
              {categories?.map((category) => (
                <option key={category?._id} value={category?._id}>
                  {category?.categoryName}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Sub Category Name"
              value={subCategoryName}
              onChange={handleCategoryNameChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              placeholder="Enter Description"
              value={description}
              onChange={handleDescriptionChange}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="form-label">Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn bg-button py-2 text-white"
              style={buttonWidth}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddSubCategory;
