import React, { useState, useEffect } from 'react';
import editLogo from "../../../assets/edit.svg";
import viewLogo from "../../../assets/view.svg"
import deactivateLogo from "../../../assets/deactivate.svg"
import deleteLogo from "../../../assets/delete.svg"
import AddRetailerList from './addRetailerList/AddRetailerList';
import EditRetailerList from './editRetailerList/EditRetailerList';
import ViewRetailerList from './viewRetailerList/ViewRetailerList';
import DeleteRetailerList from './deleteRetailerList/DeleteRetailerList';
import { useGetTotalRetailerQuery } from '../../../Services/LoginApi';
import { useDeleteCustomerSellerMutation,useDeactivateRetailerAccountMutation } from '../../../Services/ManageCustomerRetailerApi';
import { toast } from "react-toastify";
import LoadingAction from '../../../components/Loader';
import ReactPaginate from 'react-paginate';
import DeactivateRetailer from './deactivateRetailer/DeactivateRetailer';

const RetailerList = () => {
    const [fullName, setFullName] = useState('');
    const { data, error, isLoading, refetch } = useGetTotalRetailerQuery();
    const [deleteRetailer] = useDeleteCustomerSellerMutation();
    const [deactivateAccount] = useDeactivateRetailerAccountMutation()
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [retailerList, setRetailerList] = useState(['']);
    const [retailerId, setRetailerID] = useState('')
    const [showLoader, setShowLoader] = useState(false);
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [activated, setDeativated] = useState(false);


    useEffect(() => {
        setRetailerList(data?.data);
        setPageCount(data?.data.length / itemsPerPage)
    }, [data])


    const handleClose = () => {
        setShow(false);
        setShowEdit(false);
        setShowDeleteModal(false);
        setShowViewModal(false);
        setShowDeactivateModal(false)
    };

    const editModalHandler = (e, id) => {
        e.preventDefault();
        setShowEdit(true)
        setRetailerID(id)
    }
    const viewModalHandler = (e, id) => {
        e.preventDefault();
        setShowViewModal(true)
        setRetailerID(id)
    }
    const deleteModalHandler = (e, id) => {
        e.preventDefault();
        setShowDeleteModal(true);
        setRetailerID(id)
    }

    const onRetailerAddedHandler = (newCategory) => {
        setRetailerList([...retailerList, newCategory]);
    };
    const handleDeleteRetailer = () => {
        setShowLoader(true)
        deleteRetailer(retailerId)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                setShowLoader(false)
                setRetailerList(
                    retailerList.filter((customer) => customer?.id !== retailerId)
                );
                refetch();
            })
            .catch((err) => {
                setShowLoader(false)
                toast.error(err?.data?.message);
            });
    };
    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)
    }
    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }
    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search?.length > 0) {
            let searchArr = retailerList.filter((item) => item.fullName?.toLowerCase().includes(search?.toLowerCase()))
            setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
            paginated = searchArr?.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(retailerList?.length / itemsPerPage))
            paginated = retailerList?.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }
    useEffect(() => {
        paginationHandler()
    }, [retailerList, offset, search]);

    const deactivateShowHadler = (e,retailer) => {
        e.preventDefault();
        setRetailerID(retailer?._id)
        setDeativated(retailer.is_deactivated)
        setShowDeactivateModal(true)
     }

     const onPassHAndler = ()=>{
        console.log("Hello")
        setShowLoader(true)
        deactivateAccount(retailerId)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                setShowLoader(false)
                refetch();
            })
            .catch((err) => {
                setShowLoader(false)
                toast.error(err?.data?.message);
            });
     }
    return (
        <>
            <main id="main" className="main mt-0 h-100 min-vh-80">
                <div className="pagetitle">
                    <h1>Manage Retailers</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="">Home</a></li>
                            <li className="breadcrumb-item active">Manage Retailers</li>
                        </ol>
                    </nav>
                </div>
                {showDeactivateModal && <DeactivateRetailer showDeactivateModal={showDeactivateModal} handleClose={handleClose} onPassHAndler={onPassHAndler} activated={activated}/>}
                { }<AddRetailerList show={show} handleClose={handleClose} onRetailerAdded={onRetailerAddedHandler} />
                {showEdit && (<EditRetailerList showEdit={showEdit} handleClose={handleClose} retailerId={retailerId} />)}
                {showViewModal && (<ViewRetailerList showViewModal={showViewModal} handleClose={handleClose} retailerId={retailerId} />)}
                {showDeleteModal && (<DeleteRetailerList showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteRetailer} />)}
                <div className="container-fluid mt-4 pt-2">
                    <div className="row">
                        <div className="col-md-12 px-0">

                            <h6 className="text-color fw-bolder mb-3">Retailers Lists</h6>

                            <div className="clearfix mb-4 mt-2">
                                <div className="float-start">
                                    <div className="search-bar mt-1">
                                        <form className="d-flex" role="search">
                                            <div className="border-rounded-15">
                                                <div className="input-group">
                                                    <input type="text"
                                                        onChange={handleSearch}
                                                        className="form-control border-0 bg-none" placeholder="Search..." />
                                                    <span className="input-group-text bg-none border-0">
                                                        <i className="b- bi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="float-end">
                                    <button className="btn btn-main me-2" onClick={(e) => setShow(true)}><i className="bi bi-plus-lg me-2"></i>Add Retailers</button>
                                </div>
                            </div>
                            {showLoader && <LoadingAction />}
                            <div className="card border-0 table-card">
                                <div className="card-body">

                                    <div className="table-responsive">
                                        <table className="table table-striped mb-0 align-middle common-table ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S no.</th>
                                                    <th scope="col">Retailer Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Mobile Number</th>
                                                    <th scope="col">Registration Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginated && paginated?.map((retailer, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>
                                                        <td>{retailer?.fullName}</td>
                                                        <td>{retailer?.email}</td>
                                                        <td>{retailer?.mobileNumber}</td>
                                                        <td>{retailer?.createdAt?.substring(0, 10)}</td>
                                                        <td> <span className={retailer.is_deactivated?"status-active":"status"}>{retailer.is_deactivated?"Active":"Deactivated"}</span></td>
                                                        <td>
                                                        <button class="btn btn-deactivate me-2 action-wh" onClick={(e) => deactivateShowHadler(e, retailer)}><img src={deactivateLogo} alt="image" class="img-fluid action-image" /></button>
                                                            <button className="btn btn-edit me-2 action-wh" onClick={(e) => editModalHandler(e, retailer?._id)}><img src={editLogo} alt="image" className="img-fluid action-image" /></button>
                                                            <button className="btn btn-view  me-2 action-wh" onClick={(e) => viewModalHandler(e, retailer?._id)}><img src={viewLogo} alt="image" className="img-fluid action-image" /></button>
                                                            <button className="btn btn-delete  me-2 action-wh" onClick={(e) => deleteModalHandler(e, retailer?._id)}><img src={deleteLogo} alt="image" className="img-fluid action-image" /></button>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {paginated?.length == 0 && <span className="no-data my-4 fw-bold"><p>No Data Found</p></span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {retailerList?.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }

            </main>
        </>
    )
}

export default RetailerList