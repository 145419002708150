import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {useGetSupportDetailQuery} from "../../../Services/AboutUsApi"

const buttonWidth = {
    width: '100%'
};

const ViewUserSupport = (props) => {
    const { showViewModal, handleClose,supportId } = props;
    const { data } = useGetSupportDetailQuery(supportId);
    const [supportDetail,setSupportDetail] = useState('')

    const handleSubmit = () => {
        handleClose();
    }
    useEffect(()=>{
        setSupportDetail(data?.data)
    },[data,supportId])

    return (
        <Modal show={showViewModal} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className='h4'>View User Support </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className='px-md-5'>
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="row mb-3">
                        <div className="col mb-4">
                            <label className="form-label">User Name</label>
                            <p className='data-p-tag'>{supportDetail?.user?.fullName}</p>
                        </div>
                        <div className="col mb-4">
                            <label className="form-label">Email</label>
                            <p className='data-p-tag'>{supportDetail?.email}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col mb-4">
                            <label className="form-label">Subject</label>
                            <p className='data-p-tag'>{supportDetail?.subject}</p>
                        </div>
                        <div className="col mb-4">
                            <label className="form-label">Description</label>
                            <p className='data-p-tag'>{supportDetail?.description}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col mb-4">
                            <label className="form-label">Created Date</label>
                            <p className='data-p-tag'>{supportDetail?.createdAt?.split('T')[0]}</p>
                        </div>
                    </div>

                    <div className="text-center mb-4">
                        <button
                            type="submit"
                            className="btn bg-button py-3 text-white"
                            style={buttonWidth}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};


export default ViewUserSupport