import React, { useState } from 'react'
import Sidebars from '../../components/sidebars/Sidebars'
import Header from '../../components/header/Header'
import Categories from './categories/Categories'
import SubCategory from './subCategory/SubCategory'
import Footer from '../../components/footer/Footer'

const ManageCategories = () => {
  const [childData, setChildData] = useState('productCategory');


  const handleChildData = (data) => {
    setChildData(data);
  };
  return (
    <>
      <Sidebars sendDataToParent={handleChildData} />
      <Header />

      {
        childData === 'productCategory' && <Categories />
      }
      {
        childData === 'subCategory' && <SubCategory />
      }
      <Footer />
    </>
  )
}

export default ManageCategories