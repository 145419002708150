import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import loginIcon from "../../assets/login.jpg";
import logoColor from "../../assets/logo-color.svg";
import {FormValidation,FormValidationOnChange} from "./LoginValidation";
import {useLoginPostMutation} from "../../Services/LoginApi";
import {doLogin} from "../../auth/index"
import { toast } from "react-toastify";
import LoadingAction from '../Loader';
import { BiSolidHide,BiShow } from "react-icons/bi";

const Login = () => {
   const navigate = useNavigate();
   const [addLoginFormData] = useLoginPostMutation();
   const [inputField, setInputField] = useState({
      email: "",
      password: "",

   });
   const [showPassword, setShowPassword] = useState(false);
   const [showLoader, setShowLoader] = useState(false);
   const [rememberMe, setRememberMe] = useState(false);
   const [errField, setErrField] = useState({
      emailErr: "",
      passwordErr: "",
   });

   const loginHandler = (e) => {
      e.preventDefault()
      //navigate("/dashboard")
      if (FormValidation({ inputField, setErrField })) {
         inputField['role']='admin'
         setShowLoader(true)
         addLoginFormData(inputField)
             .unwrap()
             .then((res) => {
                 doLogin(res?.data?.token,res?.data?.fullName,res?.data?.email)
                 toast.success(res?.message);
                 setShowLoader(false);
                 if (res?.success) {
                     navigate('/dashboard');
                 }
             })
             .catch((err) => {
               setShowLoader(false);
                 toast.error(err?.data?.message);
             });
     }
     if (rememberMe) {
      document.cookie = `rememberMe=${inputField.email}:${inputField.password}; max-age=3600`;
  }

   }
   const handleRememberMeChange = () => {
      setRememberMe(!rememberMe);
  };
   const inputChangeHandler = (e) => {
      setInputField({ ...inputField, [e.target.name]: e.target.value });
       let inputName = e.target.name
       let inputValue = e?.target?.value
       FormValidationOnChange({ inputValue, setErrField, inputName })
   }

   useEffect(() => {
      const cookieString = document.cookie;
      const cookies = cookieString.split("; ");
      for (const cookie of cookies) {
         const [name, value] = cookie.split("=");
         if (name === "rememberMe") {
            const [savedUsername, savedPassword] = value.split(":");
            setInputField({
               email: savedUsername,
               password: savedPassword,
            });
            setRememberMe(true);
         }
      }
   }, []);
   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
  

   return (
      <div className="container">
         <div className="row justify-content-center align-items-center min-vh-100 h-100">
            <div className="col-md-5 dmobile-none">
               <div className="position-relative">
                  <img src={loginIcon} alt="loginIcon" className="img-fluid z-3" />
               </div>
            </div>
            {showLoader && <LoadingAction />}
            <div className="col-1 decrease dmobile-none"></div>
            <div className="col-md-5">
               <div className="">
                  <img src={logoColor} alt="logo" className="img-fluid mb-xl-4 mb-lg-0 mb-4 mx-auto d-block" width="220" />
                  <h6 className="text-uppercase fw-bolder mb-1 pt-4 text-color">Login to Account</h6>
                  <p className="mb-4 small">Please enter your email and password to continue</p>
               </div>
               <form action="" className="form-login">
                  <div className="mb-4">
                     <label htmlFor="email" className="form-label small fw-semibold text-black">Email address</label>
                     <input type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        aria-label="email"
                        aria-describedby="email"
                        name="email"
                        value={inputField.email}
                        onChange={inputChangeHandler}
                     />
                     {errField?.emailErr?.length > 0 && (
                        <span className="error">{errField?.emailErr}</span>
                     )}
                  </div>
                  <div className="mb-3 position-relative">
                     <label htmlFor="password" className="form-label small fw-semibold text-black">Password</label>
                     <input type={showPassword ? "text" : "password"} 
                     className="form-control" 
                     placeholder="Enter Password" 
                     aria-label="password" 
                     aria-describedby="password" 
                     name="password"
                     value={inputField.password}
                     onChange={inputChangeHandler}
                     />
                     <div className='password-hide-show' onClick={togglePasswordVisibility}>{showPassword ? <BiShow /> : <BiSolidHide />} </div>
                     {errField?.passwordErr?.length > 0 && (
                                        <span className="error">{errField?.passwordErr}</span>
                                    )}
                  </div>
                  <div className="mb-4 form-check">
                     <input type="checkbox" 
                     className="form-check-input" 
                     id="rememberMe"
                     checked={rememberMe}
                     onChange={handleRememberMeChange}
                     />
                     <label className="form-check-label text-black" htmlFor="exampleCheck1">Remember me</label>
                  </div>
                  <button type="submit"
                     onClick={loginHandler}
                     className="btn bg-button w-100 text-white py-3 mt-3">Log in</button>
                  <div className="text-center mt-4">
                     <Link to={'/forgot-password'} className="text-color fw-bolder">Forgot Password?</Link>
                  </div>
               </form>
            </div>
         </div>
      </div>
   )
}

export default Login