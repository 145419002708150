import React, { useState, useEffect } from 'react'
import Sidebars from '../../components/sidebars/Sidebars'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import deleteLogo from "../../assets/delete.svg";
import viewLogo from "../../assets/view.svg";
import editLogo from "../../assets/edit.svg";
import deactivateLogo from "../../assets/deactivate.svg"
import DeleteCustomer from './deleteCustomer/DeleteCustomer';
import DeactivateCustomer from './deactivateCustomer/DeactivateCustomer';
import ViewCustomer from './viewCustomer/ViewCustomer';
import EditCustomer from './editCustomer/EditCustomer';
import AddCustomer from './addCustomer/AddCustomer';
import { useGetTotalCustomerQuery } from '../../Services/LoginApi';
import { useDeleteCustomerSellerMutation } from '../../Services/ManageCustomerRetailerApi';
import { toast } from "react-toastify";
import LoadingAction from '../../components/Loader';
import ReactPaginate from 'react-paginate';

const ManageCustomer = () => {
   const [fullName, setFullName] = useState('');
   const { data, error, isLoading, refetch } = useGetTotalCustomerQuery();
   const [deleteCustomer] = useDeleteCustomerSellerMutation();
   const [show, setShow] = useState(false);
   const [customerList, setCustomerList] = useState([])
   const [showEdit, setShowEdit] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [showDeactivateModal, setShowDeactivateModal] = useState(false);
   const [showViewModal, setShowViewModal] = useState(false);
   const [customerId, setCustomerID] = useState('')
   const [showLoader, setShowLoader] = useState(false);
   const [users, setUsers] = useState([])
   const [paginated, setPaginated] = useState([])
   const [page, setPage] = useState(0)
   const [offset, setOffset] = useState(0)
   const [itemsPerPage, setItemsPerPage] = useState(10)
   const [pageCount, setPageCount] = useState(0)
   const [search, setSearch] = useState('')

   useEffect(() => {
         setCustomerList(data?.data)
         setPageCount(data?.data?.length / itemsPerPage)
   }, [data])

   const handleClose = () => {
      setShow(false);
      setShowEdit(false);
      setShowDeleteModal(false);
      setShowDeactivateModal(false);
      setShowViewModal(false);
   };

   const deactivateShowHadler = (e) => {
      e.preventDefault();
      setShowDeactivateModal(true)
   }
   const viewShowHadler = (e, id) => {
      e.preventDefault();
      setShowViewModal(true)
      setCustomerID(id)
   }
   const editShowHadler = (e, id) => {
      e.preventDefault();
      setShowEdit(true)
      setCustomerID(id)
   }

   const deleteShowHadler = (e, id) => {
      e.preventDefault();
      setShowDeleteModal(true);
      setCustomerID(id)
   }
   const onCustomerAddedHandler = (newCategory) => {
      setCustomerList([...customerList, newCategory]);
   };

   const handleDeleteCustomer = () => {
      setShowLoader(true)
      deleteCustomer(customerId)
         .unwrap()
         .then((res) => {
            toast.success(res?.message);
            setShowLoader(false)
            setCustomerList(
               customerList.filter((customer) => customer?.id !== customerId)
            );
            refetch();
         })
         .catch((err) => {
            setShowLoader(false)
            toast.error(err?.data?.message);
         });
   };
   const handlePageClick = (e) => {
      setPage(e.selected)
      const newOffset = (e.selected * itemsPerPage);
      setOffset(newOffset)
   }
   const handleSearch = (e) => {
      setSearch(e.target.value)
      setOffset(0)
      setPage(0)

   }
   const paginationHandler = () => {
      const endOffset = offset + itemsPerPage;
      let paginated = []
      if (search?.length > 0) {
         let searchArr = customerList.filter((item) => item.fullName?.toLowerCase().includes(search?.toLowerCase()))
         setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
         paginated = searchArr?.slice(offset, endOffset)
      }
      else {
         setPageCount(Math.ceil(customerList?.length / itemsPerPage))
         paginated = customerList?.slice(offset, endOffset)
      }
      setPaginated(paginated)
   }
   useEffect(() => {
      paginationHandler()
   }, [customerList, offset, search]);

   return (
      <>
         <Sidebars />
         <Header />
         <main id="main" class="main mt-0 h-100 min-vh-80">
            <div class="pagetitle">
               <h1>Manage Customers</h1>
               <nav>
                  <ol class="breadcrumb">
                     <li class="breadcrumb-item"><a href="">Home</a></li>
                     <li class="breadcrumb-item active">Manage Customers</li>
                  </ol>
               </nav>
            </div>
            <DeactivateCustomer showDeactivateModal={showDeactivateModal} handleClose={handleClose} />
            {show && (<AddCustomer show={show} handleClose={handleClose} onCustomerAdded={onCustomerAddedHandler} />)}
            {showViewModal && (<ViewCustomer showViewModal={showViewModal} handleClose={handleClose} customerId={customerId} />)}
            {showEdit && (<EditCustomer showEdit={showEdit} handleClose={handleClose} customerId={customerId} />)}
            {showDeleteModal && (<DeleteCustomer showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteCustomer} />)}
            <div class="container-fluid mt-4 pt-2">
               <div class="row">
                  <div class="col-md-12 px-0">

                     <h6 class="text-color fw-bolder mb-3">Customer Lists</h6>

                     <div class="clearfix mb-4 mt-2">
                        <div class="float-start">
                           <div class="search-bar mt-1">
                              <form class="d-flex" role="search">
                                 <div class="border-rounded-15">
                                    <div class="input-group">
                                       <input
                                          type="text"
                                          onChange={handleSearch}
                                          class="form-control border-0 bg-none" placeholder="Search..." />
                                       <span class="input-group-text bg-none border-0">
                                          <i class="b- bi-search"></i>
                                       </span>
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                        <div class="float-end">
                           <button class="btn btn-main me-2" onClick={(e) => setShow(true)}><i class="bi bi-plus-lg me-2"></i>Add User</button>
                        </div>
                     </div>
                     {showLoader && <LoadingAction />}
                     <div class="card border-0 table-card">
                        <div class="card-body">

                           <div class="table-responsive">
                              <table class="table table-striped mb-0 align-middle common-table ">
                                 <thead>
                                    <tr>
                                       <th scope="col">S no.</th>
                                       <th scope="col">Name</th>
                                       <th scope="col">Email</th>
                                       <th scope="col">Mobile Number</th>
                                       <th scope="col">Registration Date</th>
                                       <th scope="col">Status</th>
                                       <th scope="col">Action</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {paginated && paginated?.map((customer, index) => (
                                       <tr key={index}>
                                          <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>

                                          <td>{customer?.fullName}</td>
                                          <td>{customer?.email}</td>
                                          <td>{customer?.mobileNumber}</td>
                                          <td>{customer?.createdAt?.substring(0, 10)}</td>
                                          <td> <span class="status">Inactive</span></td>
                                          <td>
                                             <button class="btn btn-deactivate me-2 action-wh" onClick={(e) => deactivateShowHadler(e, customer?._id)}><img src={deactivateLogo} alt="image" class="img-fluid action-image" /></button>
                                             <button class="btn btn-edit me-2 action-wh" onClick={(e) => editShowHadler(e, customer?._id)}><img src={editLogo} alt="image" class="img-fluid action-image" /></button>
                                             <button class="btn btn-view  me-2 action-wh" onClick={(e) => viewShowHadler(e, customer?._id)}><img src={viewLogo} alt="image" class="img-fluid action-image" /></button>
                                             <button class="btn btn-delete  me-2 action-wh" onClick={(e) => deleteShowHadler(e, customer?._id)}><img src={deleteLogo} alt="image" class="img-fluid action-image" /></button>

                                          </td>
                                       </tr>
                                    ))}


                                 </tbody>
                              </table>
                              {customerList?.length == 0 && <span className="no-data my-4 fw-bold"><p>No Data Found</p></span>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
            {customerList?.length > 10 &&
               <ReactPaginate
                  breakLabel="..."
                  breakClassName={'break'}
                  nextLabel="next >"
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  onPageChange={handlePageClick}
                  forcePage={page}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< previous"

               />
            }
         </main>
         <Footer />
      </>
   )
}

export default ManageCustomer