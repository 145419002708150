import React from 'react'

const Footer = () => {
  return (
    <footer id="footer" className="footer">
         <div className="copyright">
            &copy; Copyright <strong><span>DealShaq</span></strong>. All Rights Reserved
         </div>
      </footer>
  )
}

export default Footer