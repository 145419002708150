import React, { useState, useEffect } from "react";
import profileImage from "../../../assets/profile-img.jpg";
import AddSubCategory from "./addSubCategory/AddSubCategory";
import EditSubCategory from "./editSubCategory/EditSubCategory";
import DeleteSubCategory from "./deleteSubCategory/DeleteSubCategory";
import {
  useGetSubCategoryQuery,
  useDeleteSubCategoryMutation,
} from "../../../Services/SubCategoryApi";
import { GetUrl } from "../../../config/GetUrl";
import { toast } from "react-toastify";
import LoadingAction from "../../../components/Loader";

const SubCategory = () => {
  const { data: retrieveSubCategory, refetch } = useGetSubCategoryQuery();
  const [deleteSubCategory] = useDeleteSubCategoryMutation();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [subCategories, setSubCategories] = useState([""]);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const AddSubCategoryHandler = () => {
    setShow(true);
  };
  useEffect(() => {
    setSubCategories(retrieveSubCategory?.data);
  }, [retrieveSubCategory]);
  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowDeleteModal(false);
  };

  const handleAddSubCategory = (newCategory) => {
    setSubCategories([...subCategories, newCategory]);
  };

  const editShowHandler = (e, id) => {
    setShowEdit(true);
    setSubCategoryId(id);
  };
  const deleteShowHandler = (e, id) => {
    setShowDeleteModal(true);
    setSubCategoryId(id);
  };

  const handleDeleteCategory = () => {
    setShowLoader(true)
    deleteSubCategory(subCategoryId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setSubCategories(
          subCategories?.filter((category) => category?.id !== subCategoryId)
        ); // Update state after deletion
        refetch(); // Refresh the category list after deletion
        setShowLoader(false)
      })
      .catch((err) => {
        setShowLoader(false)
        toast.error(err?.data?.message);
      });
  };

  return (
    <>
      <main id="main" className="main mt-0 h-100 min-vh-80">
        <div className="pagetitle">
          <h1>Manage Categories</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item active">Manage Categories</li>
            </ol>
          </nav>
        </div>
        {show && (
          <AddSubCategory
            show={show}
            handleClose={handleClose}
            onSubCategoryAdded={handleAddSubCategory}
          />
        )}
        {showEdit && (
          <EditSubCategory
            showEdit={showEdit}
            handleClose={handleClose}
            subCategoryId={subCategoryId}
          />
        )}
        {showDeleteModal && (
          <DeleteSubCategory
            showDeleteModal={showDeleteModal}
            handleClose={handleClose}
            handleDelete={handleDeleteCategory}
          />
        )}
        <div className="container-fluid mt-4 pt-2">
          <div className="row">
            <div className="col-md-12 px-0">
              <h6 className="text-color fw-bolder mb-3">Sub Categories List</h6>
              {showLoader && <LoadingAction />}
              <div className="clearfix mb-4 mt-2">
                <div className="float-start">
                  <div className="search-bar mt-1">
                    <form className="d-flex" role="search">
                      <div className="border-rounded-15">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control border-0 bg-none"
                            placeholder="Search..."
                          />
                          <span className="input-group-text bg-none border-0">
                            <i className="b- bi-search"></i>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="float-end">
                  <button
                    className="btn btn-main me-2"
                    onClick={AddSubCategoryHandler}
                  >
                    <i className="bi bi-plus-lg me-2"></i>Add Sub Category
                  </button>
                </div>
              </div>

              <div className="card border-0 table-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 align-middle common-table ">
                      <thead>
                        <tr>
                          <th scope="col">S no.</th>
                          <th scope="col">Category Name</th>
                          <th scope="col">Sub Category Name</th>
                          <th scope="col">Description</th>
                          <th scope="col">Image</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subCategories?.map((item, index) => (
                          <tr key={item?._id}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.category?.categoryName}</td>
                            <td>{item.subCategoryName}</td>
                            <td>{item.description}</td>
                            <td>
                              <img
                                src={GetUrl?.IMAGE_URL + item?.images}
                                alt="Profile"
                                width="40"
                                height="40"
                                className="rounded-circle"
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-edit me-2 action-wh"
                                onClick={(e) => editShowHandler(e, item?._id)}
                              >
                                <img
                                  src="assets/img/edit.svg"
                                  alt="image"
                                  className="img-fluid action-image"
                                />
                              </button>
                              <button
                                className="btn btn-delete  me-2 action-wh"
                                onClick={(e) => deleteShowHandler(e, item?._id)}
                              >
                                <img
                                  src="assets/img/delete.svg"
                                  alt="image"
                                  className="img-fluid action-image"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SubCategory;
