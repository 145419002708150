import React,{useEffect, useState} from "react";
import EditCustomerList from "./editCustomerList/EditCustomerList";
import ViewCustomerList from "./viewCustomerList/ViewCustomerList";
import DeleteCustomerList from "./deleteCustomerList/DeleteCustomerList";
import { useDeleteCustomerSellerMutation } from '../../../Services/ManageCustomerRetailerApi';
import { toast } from "react-toastify";
import LoadingAction from "../../../components/Loader";
import DeactivateCustomerList from "./deactivateCustomerList/DeactivateCustomerList";

const CustomerList = (props) => {
   const {customerList,setCustomerList,refetch}=props;
   const [showEdit, setShowEdit] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [showDeactivateModal, setShowDeactivateModal] = useState(false);
   const [showViewModal, setShowViewModal] = useState(false);
   const [customerId,setCustomerID] = useState('')
   const [showLoader, setShowLoader] = useState(false);
   const [deleteCustomer] = useDeleteCustomerSellerMutation();
   const [userList,setUserList] = useState([])

   const handleClose = () => {
    setShowEdit(false);
    setShowDeleteModal(false);
    setShowDeactivateModal(false);
    setShowViewModal(false);
 };
 const deactivateShowHadler = (e) => {
  e.preventDefault();
  setShowDeactivateModal(true)
}
const viewShowHadler = (e,id) => {
  e.preventDefault();
  setShowViewModal(true)
  setCustomerID(id)
}
const editShowHadler = (e,id) => {
  e.preventDefault();
  setShowEdit(true)
  setCustomerID(id)
}

const deleteShowHadler = (e,id) => {
  e.preventDefault();
  setShowDeleteModal(true);
  setCustomerID(id)
}
const handleDeleteCustomer = () => {
  setShowLoader(true)
  deleteCustomer(customerId)
    .unwrap()
    .then((res) => {
      toast.success(res?.message);
      setShowLoader(false)
      setCustomerList(
        customerList.filter((customer) => customer?.id !== customerId)
      ); 
      refetch();
    })
    .catch((err) => {
      setShowLoader(false)
      toast.error(err?.data?.message);
    });
};

useEffect(()=>{
 let users = customerList?.slice(0,3)
   setUserList(users)
},[customerList])

  return (
    <div className="col-md-6 text-nowrap">
      <h6 className="text-color fw-bolder mb-3">Customer Lists</h6>
      <DeactivateCustomerList showDeactivateModal={showDeactivateModal} handleClose={handleClose}/>
            {showEdit && (<EditCustomerList showEdit={showEdit} handleClose={handleClose} customerId={customerId}/>)} 
           {showViewModal && (<ViewCustomerList showViewModal={showViewModal} handleClose={handleClose} customerId={customerId}/>)}  
            {showDeleteModal && (<DeleteCustomerList showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteCustomer}/>)} 
      <div className="card border-0 table-card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped mb-0 align-middle common-table ">
              <thead>
              {showLoader && <LoadingAction />}
                <tr>
                  <th scope="col">S no.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Registration Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {userList && userList?.map((customer,index)=>(

                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{customer?.fullName}</td>
                  <td>{customer?.email}</td>
                  <td>{customer?.mobileNumber}</td>
                  <td>{customer?.createdAt?.substring(0, 10)}</td>
                  <td>
                    {" "}
                    <span className="status">Inactive</span>
                  </td>
                  <td>
                    <button className="btn btn-deactivate me-2 action-wh">
                      <img
                        src="assets/img/deactivate.svg"
                        alt="image"
                        className="img-fluid action-image"
                        onClick={(e)=>deactivateShowHadler(e,customer?._id)}
                      />
                    </button>
                    <button className="btn btn-edit me-2 action-wh">
                      <img
                        src="assets/img/edit.svg"
                        alt="image"
                        className="img-fluid action-image"
                        onClick={(e)=>editShowHadler(e,customer?._id)}
                      />
                    </button>
                    <button className="btn btn-view  me-2 action-wh">
                      <img
                        src="assets/img/view.svg"
                        alt="image"
                        className="img-fluid action-image"
                        onClick={(e)=>viewShowHadler(e,customer?._id)}
                      />
                    </button>
                    <button className="btn btn-delete  me-2 action-wh">
                      <img
                        src="assets/img/delete.svg"
                        alt="image"
                        className="img-fluid action-image"
                        onClick={(e)=>deleteShowHadler(e,customer?._id)}
                      />
                    </button>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
