import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const aboutUsApi = createApi({
  reducerPath: "aboutUsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    AboutUsPost: builder.mutation({
      query: (data) => ({
        url: "/admin/add-about-us",
        method: "POST",
        body: data,
        headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        
      }),
    }),
    GetSupportList: builder.query({
      query: () => ({
        url: "/admin/get-support",
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetSupportDetail: builder.query({
      query: (id) => ({
        url: `/admin/get-support-detail/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    DeleteSupport: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-support/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
  }),
});

export const {
    useAboutUsPostMutation,
    useGetSupportListQuery,
    useGetSupportDetailQuery,
    useDeleteSupportMutation
} = aboutUsApi;
