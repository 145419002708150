import React, { useState } from 'react';
import deactivateIcon from "../../../../assets/delete.svg";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

const DeleteSellerList = (props) => {
    const { showDeleteModal, handleClose,handleDelete } = props;

    const confirmedDeleteHandler = ()=>{
        handleDelete();
        handleClose();
    }

    return (
        <Modal show={showDeleteModal} onHide={handleClose} centered>
            <Modal.Header closeButton={false} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Image src={deactivateIcon} width='40' height='40' rounded />
                </div>
            </Modal.Header>
            <Modal.Body className='text-center px-md-5 '>
                <h4 className='text-red-sure'>Are you sure you want to delete this retailer?</h4>
            </Modal.Body>
            <Modal.Footer className='border-0 d-flex justify-content-between w-75 mx-auto mb-4 pb-0'>
                <button type="submit"  onClick={confirmedDeleteHandler} className='btn bg-button py-2 text-white' >
                    Yes
                </button>
                <Button variant="secondary" onClick={handleClose} className='btn btn-closed px-md-4'>
                No
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteSellerList