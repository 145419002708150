import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const faqApi = createApi({
  reducerPath: "faqApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    FaqPost: builder.mutation({
      query: (data) => ({
        url: "/admin/add-faq",
        method: "POST",
        body: data,
        headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        
      }),
    }),
    GetFaq: builder.query({
        query: () => ({
          url: "/admin/get-faqs",
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        }),
      }),
      GetSingleFaq: builder.query({
        query: (id) => ({
          url: `/admin/get-single-faqs/${id}`,
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        }),
      }),
      EditFaq: builder.mutation({
        query: ({ id, data }) => ({
          url: `/admin/edit-faqs/${id}`,
          method: "PUT",
          body: data,
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        }),
      }),
      deleteFaq: builder.mutation({
        query: (id) => ({
          url: `/admin/delete-faqs/${id}`,
          method: "DELETE",
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        }),
      }),

  }),
});

export const {
    useFaqPostMutation,
    useGetFaqQuery,
    useGetSingleFaqQuery,
    useEditFaqMutation,
    useDeleteFaqMutation
} = faqApi;
