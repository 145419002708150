import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useCreatePromoCodePostMutation } from "../../../Services/PromoCodeApi";
import LoadingAction from "../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const AddPromoCode = (props) => {
  const { show, handleClose, onCharityAdded } = props;
  const [addPromoCode] = useCreatePromoCodePostMutation();
  const [inputField, setInputField] = useState({
    code:"",
    discount:""
  });
  const [showLoader, setShowLoader] = useState(false);

  const handleNameChange = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoader(true);
    addPromoCode(inputField)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        onCharityAdded(res.data);
        handleClose();
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h4 ">Add Promo Code</Modal.Title>
        </div>
      </Modal.Header>
      {showLoader && <LoadingAction />}
      <Modal.Body className="px-md-5">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4">
            <label className="form-label">Promo Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter  Name"
              name="code"
              value={inputField?.code}
              onChange={handleNameChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Discount</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter  Discount"
              name="discount"
              value={inputField?.discount}
              onChange={handleNameChange}
            />
          </div>

          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn bg-button py-3 text-white"
              style={buttonWidth}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};




export default AddPromoCode