import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/Login"
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import OtpVerification from './components/otpVerification/OtpVerification';
import ResetPassword from './components/resetPassword/ResetPassword';
import Dashboard from './pages/dashboard/Dashboard';
import ManageCustomer from './pages/manageCustomers/ManageCustomer';
import ManageRetailers from './pages/manageRetailers/ManageRetailers';
import ManageCategories from './pages/manageCategories/ManageCategories';
import SaleFeeManagement from './pages/saleFeeManagement/SaleFeeManagement';
import OrderManagement from './pages/orderManagement/OrderManagement';
import ManageTransactions from './pages/manageTrasactions/ManageTransactions';
import UserSupport from './pages/userSupport/UserSupport';
import PromoCode from './pages/promoCode/PromoCode';
import Charity from './pages/charity/Charity';
import Banner from './pages/banner/Banner';
import Faq from './pages/fag/Faq';
import AddFaq from './pages/fag/addFaq/AddFaq';
import TermAndCondition from './pages/termAndCondition/TermAndCondition';
import PrivacyAndPolicy from './pages/privacyAndPolicy/PrivacyAndPolicy';
import AboutUs from './pages/aboutUs/AboutUs';
import Private from './components/Private';
import PageNotFound from './pageNotFound/PageNotFound';



function App() {
  return (

    <Routes>
      <Route path="/" element={<Navigate to={'/login'} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/otp-verify" element={<OtpVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />

      <Route path="/" element={<Private />} >
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="manage-customer" element={<ManageCustomer />} />
        <Route path="manage-retailers" element={<ManageRetailers />} />
        <Route path="manage-categories" element={<ManageCategories />} />
        {/* <Route path="sale-fee-management" element={<SaleFeeManagement />} /> */}
        <Route path="order-management" element={<OrderManagement />} />
        <Route path="manage-transactions" element={<ManageTransactions />} />
        <Route path="user-support" element={<UserSupport />} />
        <Route path="promo-code" element={<PromoCode />} />
        <Route path="charity" element={<Charity />} />
        <Route path="banner" element={<Banner />} />
        <Route path="faq" element={<Faq />} />
        <Route path="add-faq" element={<AddFaq />} />
        <Route path="term-and-condition" element={<TermAndCondition />} />
        <Route path="privacy-and policy" element={<PrivacyAndPolicy />} />
        <Route path="about-us" element={<AboutUs />} />

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>

  )
}



export default App;
