import React, { useState, useEffect } from "react";
import Sidebars from "../../components/sidebars/Sidebars";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TotalCustomer from "./totalCustomer/TotalCustomer";
import TotalRetailer from "./totalRetailer/TotalRetailer";
import TotalOrder from "./totalOrder/TotalOrder";
import TotalTransaction from "./totalTransaction/TotalTransaction";
import RecentOrder from "./recentOrder/RecentOrder";
import CustomerList from "./customerList/CustomerList";
import SellerList from "./sellerList/SellerList";
import { useGetTotalCustomerQuery, useGetTotalRetailerQuery } from "../../Services/LoginApi";

const Dashboard = () => {
  const [fullName, setFullName] = useState('');
  const [totalCustomer, setTotalCustomer] = useState('')
  const [customerList, setCustomerList] = useState([''])
  const [totalRetailer, setTotalRetailer] = useState('')
  const [retailerList, setRetailerList] = useState([''])
  const { data, error, isLoading,refetch } = useGetTotalCustomerQuery({ page: 1, limit: 5, fullName });
  const { data: retailer } = useGetTotalRetailerQuery({ page: 1, limit: 5, fullName });

  useEffect(() => {
    setTotalCustomer(data?.totalCustomers)
    setCustomerList(data?.data)
  }, [data])
  useEffect(() => {
    setTotalRetailer(retailer?.totalRetailer)
    setRetailerList(retailer?.data)
    console.log(retailer);
  }, [retailer])

  return (
    <>
      <Sidebars />
      <Header />
      <main id="main" className="main mt-0 h-100 min-vh-80">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        <div class="container-fluid mt-4 px-0">
          <div class="row">
            <TotalCustomer totalCustomer={totalCustomer} setCustomerList={setCustomerList} refetch={refetch}/>
            <TotalRetailer totalRetailer={totalRetailer} />
            <TotalOrder />
            <TotalTransaction />
          </div>
        </div>
        <div class="container-fluid mt-4 px-0">
          <div class="row">
            <RecentOrder />
            <CustomerList customerList={customerList} />
            <SellerList retailerList={retailerList} />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Dashboard;
