import React from 'react'

const RetailerPending = () => {
    return (
        <>
            <main id="main" class="main mt-0 h-100 min-vh-80">
                <div class="pagetitle">
                    <h1>Manage Retailers</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="">Home</a></li>
                            <li class="breadcrumb-item active">Manage Retailers</li>
                        </ol>
                    </nav>
                </div>
                <div class="container-fluid mt-4 pt-2">
                    <div class="row">
                        <div class="col-md-12 px-0">
                            <h6 class="text-color fw-bolder mb-3">Retailers Lists</h6>
                            <div class="clearfix mb-4 mt-2">
                                <div class="float-start">
                                    <div class="search-bar mt-1">
                                        <form class="d-flex" role="search">
                                            <div class="border-rounded-15">
                                                <div class="input-group">
                                                    <input type="text" class="form-control border-0 bg-none" placeholder="Search..." />
                                                    <span class="input-group-text bg-none border-0">
                                                        <i class="b- bi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="float-end">
                                    <button class="btn btn-main me-2"><i class="bi bi-plus-lg me-2"></i>Add Retailers</button>
                                </div>
                            </div>
                            <div class="card border-0 table-card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table table-striped mb-0 align-middle common-table ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S no.</th>
                                                    <th scope="col">Retailer Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Mobile Number</th>
                                                    <th scope="col">Registration Date</th>
                                                    <th scope="col">Acceptance Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>Mark</td>
                                                    <td>mark@gmail.com</td>
                                                    <td>76754345454453</td>
                                                    <td>12 April 2024</td>
                                                    <td> <span class="status">Pending</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default RetailerPending