import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    CreateCategoryPost: builder.mutation({
      query: (data) => ({
        url: "/admin/create-category",
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetCategory: builder.query({
      query: () => ({
        url: "/admin/get-all-category",
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetSingleCategory: builder.query({
      query: (id) => ({
        url: `/admin/get-single-category/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    EditCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/edit-category/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    DeleteCategory: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-category/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
  }),
});

export const {
  useCreateCategoryPostMutation,
  useGetCategoryQuery,
  useGetSingleCategoryQuery,
  useEditCategoryMutation,
  useDeleteCategoryMutation,
} = categoryApi;
