import React, { useEffect, useState } from "react";
import editData from "../../../assets/edit.svg";
import deleteData from "../../../assets/delete.svg";
import AddCategory from "./addCategory/AddCategory";
import EditCategory from "./editCategory/EditCategory";
import DeleteCategory from "./deleteCategory/DeleteCategory";
import ReactPaginate from "react-paginate"
import {
  useGetCategoryQuery,
  useDeleteCategoryMutation,
} from "../../../Services/CategoryApi";
import { GetUrl } from "../../../config/GetUrl";
import { toast } from "react-toastify";
import LoadingAction from "../../../components/Loader";

const Categories = () => {
  const { data: retrieveCategory, refetch,isLoading } = useGetCategoryQuery();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [categories, setCategories] = useState([""]);
  const [categoryId, setCategoryId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')

  useEffect(() => {
    setCategories(retrieveCategory?.data);
    setPageCount(retrieveCategory?.data?.length / itemsPerPage)
  }, [retrieveCategory]);
  const handleAddCategory = (newCategory) => {
    setCategories([...categories, newCategory]);
  };
  const AddCategoryHandler = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowDeleteModal(false);
  };
  const editShowHandler = (e, id) => {
    e.preventDefault();
    setShowEdit(true);
    setCategoryId(id);
  };
  const deleteShowHandler = (e, id) => {
    e.preventDefault();
    setShowDeleteModal(true);
    setCategoryId(id);
  };

  const handleDeleteCategory = () => {
    setShowLoader(true)
    deleteCategory(categoryId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setShowLoader(false)
        setCategories(
          categories.filter((category) => category.id !== categoryId)
        ); 
        refetch();
      })
      .catch((err) => {
        setShowLoader(false)
        toast.error(err?.data?.message);
      });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value)
    setOffset(0)
    setPage(0)

}

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = []
    if (search?.length > 0) {
        let searchArr = categories.filter((item) => item?.categoryName?.toLowerCase().includes(search.toLowerCase()))
        setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
        paginated = searchArr?.slice(offset, endOffset)
    }
    else {
        setPageCount(Math.ceil(categories?.length / itemsPerPage))
        paginated = categories?.slice(offset, endOffset)
    }
    setPaginated(paginated)
}


  const handlePageClick = (e) => {
    setPage(e.selected)
    const newOffset = (e.selected * itemsPerPage);
    setOffset(newOffset)
}

useEffect(() => {
  paginationHandler()
}, [categories, offset, search])


  return (
    <>
      <main id="main" className="main mt-0 h-100 min-vh-80">
        <div className="pagetitle">
          <h1>Manage Categories</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Home</a>
              </li>
              <li className="breadcrumb-item active">Manage Categories</li>
            </ol>
          </nav>
        </div>
        <AddCategory
          show={show}
          handleClose={handleClose}
          onCategoryAdded={handleAddCategory}
        />
        {showEdit && (
          <EditCategory
            showEdit={showEdit}
            handleClose={() => setShowEdit(false)}
            categoryId={categoryId} // Pass categoryId to EditCategory component
            onCategoryAdded={handleAddCategory}
          />
        )}
        {showDeleteModal && (
          <DeleteCategory
            showDeleteModal={showDeleteModal}
            handleClose={handleClose}
            handleDelete={handleDeleteCategory} // Pass handleDeleteCategory function
          />
        )}

        <div className="container-fluid mt-4 pt-2">
          <div className="row">
            <div className="col-md-12 px-0">
              <h6 className="text-color fw-bolder mb-3">Categories List</h6>
              {showLoader && <LoadingAction />}
              <div className="clearfix mb-4 mt-2">
                <div className="float-start">
                  <div className="search-bar mt-1">
                    <form className="d-flex" role="search">
                      <div className="border-rounded-15">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control border-0 bg-none"
                            placeholder="Search..."
                            onChange={handleSearch}
                          />
                          <span className="input-group-text bg-none border-0">
                            <i className="b- bi-search"></i>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="float-end">
                  <button
                    className="btn btn-main me-2"
                    onClick={AddCategoryHandler}
                  >
                    <i className="bi bi-plus-lg me-2"></i>Add Category
                  </button>
                </div>
              </div>

              <div className="card border-0 table-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 align-middle common-table ">
                      <thead>
                        <tr>
                          <th scope="col">S no.</th>
                          <th scope="col">Category Name</th>
                          <th scope="col">Description</th>
                          <th scope="col">Image</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginated && paginated?.map((category, index) => (
                          <tr key={index}>
                            <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>
                            <td>{category?.categoryName}</td>
                            <td>{category?.description?.length>20?category?.description?.substring(0,30)+'...':category?.description}</td>
                            <td>
                              <img
                                src={GetUrl?.IMAGE_URL + category?.images}
                                alt="Profile"
                                width="40"
                                height="40"
                                className="rounded-circle"
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-edit me-2 action-wh"
                                onClick={(e) =>
                                  editShowHandler(e, category?._id)
                                }
                              >
                                <img
                                  src={editData}
                                  alt="image"
                                  className="img-fluid action-image"
                                />
                              </button>
                              <button
                                className="btn btn-delete  me-2 action-wh"
                                onClick={(e) =>
                                  deleteShowHandler(e, category?._id)
                                }
                              >
                                <img
                                  src={deleteData}
                                  alt="image"
                                  className="img-fluid action-image"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                    {paginated?.length == 0 && <span className="no-data my-4 fw-bold"><p>No Data Found</p></span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </main>
      {categories?.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
    </>
  );
};

export default Categories;
