import React,{useState} from 'react'
import Sidebars from '../../components/sidebars/Sidebars'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer';
import {usePrivacyPolicyPostMutation} from "../../Services/PrivacyPolicy";
import { toast } from "react-toastify";

const PrivacyAndPolicy = () => {

   const [addPrivacyPolicy] = usePrivacyPolicyPostMutation()

   const [inputField, setInputField] = useState({
      title: "",
      content: "",
   });

   const submitHandler = (e) =>{
         e.preventDefault();
         addPrivacyPolicy(inputField)
             .unwrap()
             .then((res) => {
                 toast.success(res?.message);  
             })
             .catch((err) => {
                 toast.error(err?.data?.message);
             });
   }

   const inputChangeHandler = (e) => {
      setInputField({ ...inputField, [e.target.name]: e.target.value });
      
   }

  return (
    <>
    <Sidebars />
    <Header />
     <main id="main" className="main mt-0 h-100 min-vh-80">
         <div className="pagetitle">
            <h1>Privacy Policy</h1>
            <nav>
               <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Home</a></li>
                  <li className="breadcrumb-item active">Privacy Policy</li>
               </ol>
            </nav>
         </div>


          <div className="container mt-3">
                     <div className="row">
                        <div className="col-md-12 px-0">
                           <div className="card border-0 table-card">
                              <div className="card-body p-4">
                                 <form className="form-login n">

                                     <div className="mb-4">
                                       <label className="form-label">Title</label>
                                       <input type="text" 
                                       className="form-control" 
                                       placeholder="Enter Title" 
                                       name="title"
                                       value={inputField.title}
                                       onChange={inputChangeHandler}
                                       />
                                    </div>
                                    <div className="mb-4">
                                       <label className="form-label">Privacy Policy</label>
                                       <textarea 
                                        id="" cols="30" rows="10" 
                                       className="form-control" placeholder="Enter Policy"
                                       name="content"
                                       value={inputField.content}
                                       onChange={inputChangeHandler}
                                       ></textarea>
                                    </div>
                                    <button type="submit" 
                                    onClick={submitHandler}
                                    className="btn bg-button py-33 text-white">Submit</button>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

      </main>
      <Footer />
    </>
  )
}

export default PrivacyAndPolicy