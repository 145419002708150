import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
    useGetSingleFaqQuery,
    useEditFaqMutation
} from "../../../Services/FaqApi";
import LoadingAction from "../../../components/Loader";

const buttonWidth = {
    width: "100%",
};

const EditFaq = (props) => {
    const { editShowModal, handleClose, faqId } = props;
    const { data } = useGetSingleFaqQuery(faqId);
    const [editFaq] = useEditFaqMutation();
    const [showLoader, setShowLoader] = useState(false);
    const [inputField,setInputField] = useState({
        question:'',
        answer:''
    })


      useEffect(() => {
        setInputField(data?.data);
      }, [data, faqId]);

    const inputChangeHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });

    }
    const handleSubmit = (e) => {
        e.preventDefault();
       
        setShowLoader(true);
        editFaq({ id: faqId, data: inputField })
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                handleClose();
                setShowLoader(false);
            })
            .catch((err) => {
                setShowLoader(false);
                toast.error(err?.data?.message);
            });
    };

    return (
        <Modal show={editShowModal} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h4 ">Edit Charity</Modal.Title>
                </div>
            </Modal.Header>
            {showLoader && <LoadingAction />}
            <Modal.Body className="px-md-5">
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <label className="form-label">Question</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter  Name"
                            name="question"
                            value={inputField.question}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Answer</label>
                        <textarea id="" cols="4" rows="4"
                            className="form-control" placeholder="Enter Answer"
                            name="answer"
                             value={inputField.answer}
                             onChange={inputChangeHandler}
                        ></textarea>
                    </div>

                    <div className="text-center mb-4">
                        <button
                            type="submit"
                            className="btn bg-button py-3 text-white"
                            style={buttonWidth}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditFaq