import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const forgotPasswordApi = createApi({
  reducerPath: "forgotPasswordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    ForgotPasswordPost: builder.mutation({
      query: (data) => ({
        url: "/admin/forget-password",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),
    ForgotVerificationPost: builder.mutation({
      query: (data) => ({
        url: "/admin/otp-verify",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),
    CreateNewPassword: builder.mutation({
      query: (data) => ({
        url: "/admin/change-password",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
      }),
    }),

  }),
});

export const {
  useForgotPasswordPostMutation,
  useForgotVerificationPostMutation,
  useCreateNewPasswordMutation
} = forgotPasswordApi;
