import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const bannerApi = createApi({
  reducerPath: "bannerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    CreateBannerPost: builder.mutation({
      query: (data) => ({
        url: "/admin/create-banner",
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetBannerList: builder.query({
      query: () => ({
        url: "/admin/get-banner-list",
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetSingleCharity: builder.query({
      query: (id) => ({
        url: `/admin/get-single-charity/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    EditBanner: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/edit-banner/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    DeleteBanner: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-banner/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
  }),
});

export const {
  useCreateBannerPostMutation,
  useGetBannerListQuery,
  useGetSingleCharityQuery,
  useEditBannerMutation,
  useDeleteBannerMutation,
} = bannerApi;
