import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useGetCustomerRetailerQuery } from '../../../Services/ManageCustomerRetailerApi';

const buttonWidth = {
    width: '100%'
};

const ViewCustomer = (props) => {
    const { showViewModal, handleClose,customerId } = props;
    const { data } = useGetCustomerRetailerQuery(customerId);
    const [singleCustomer,setSignleCustomer] = useState('')

    const handleSubmit = () => {
        handleClose();
    }
    useEffect(()=>{
     setSignleCustomer(data?.data)
    },[data,customerId])

    return (
        <Modal show={showViewModal} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className='h4'>View Customer</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className='px-md-5'>
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="row mb-3">
                        <div className="col mb-4">
                            <label className="form-label">Name</label>
                            <p className='data-p-tag'>{singleCustomer?.fullName}</p>
                        </div>
                        <div className="col mb-4">
                            <label className="form-label">Email</label>
                            <p className='data-p-tag'>{singleCustomer?.email}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col mb-4">
                            <label className="form-label">Mobile Number</label>
                            <p className='data-p-tag'>{singleCustomer?.mobileNumber}</p>
                        </div>
                        <div className="col mb-4">
                            <label className="form-label">Registration Date</label>
                            <p className='data-p-tag'>{singleCustomer?.createdAt?.substring(0, 10)}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col mb-4">
                            <label className="form-label">Status</label>
                            <p className='data-p-tag'>Inactive</p>
                        </div>
                    </div>

                    <div className="text-center mb-4">
                        <button
                            type="submit"
                            className="btn bg-button py-3 text-white"
                            style={buttonWidth}
                        >
                            View
                        </button>
                    </div>
                </form>
                
            </Modal.Body>
        </Modal>
    );
};

export default ViewCustomer