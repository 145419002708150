import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useCreateBannerPostMutation } from "../../../Services/BannerApi";
import LoadingAction from "../../../components/Loader";

const buttonWidth = {
  width: "100%",
};

const AddBanner = (props) => {
  const { show, handleClose, onCharityAdded } = props;
  const [addCharity] = useCreateBannerPostMutation();
  const [regularImage, setRegularImage] = useState(null);
  const [discountImage, setDiscountImage] = useState(null);
  const [adsImage, setAdsImage] = useState(null);
  const [settingsAdsImage, setSettingsAdsImage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);


  const handleRegularImageChange = (e) => {
    setRegularImage(e.target.files[0]);
  };
  const handleDiscontImageChange = (e) => {
    setDiscountImage(e.target.files[0]);
  };
  const handleAdsImageChange = (e) => {
    setAdsImage(e.target.files[0]);
  };
  const handleSettingAdsImageChange = (e) => {
    setSettingsAdsImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("regular_bg_image", regularImage);
    formData.append("discount_bg_image", discountImage);
    formData.append("ads_image", adsImage);
    formData.append("setting_ads_image", settingsAdsImage);
    setShowLoader(true);
    addCharity(formData)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        onCharityAdded(res.data);
        handleClose();
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h4 ">Add Banner</Modal.Title>
        </div>
      </Modal.Header>
      {showLoader && <LoadingAction />}
      <Modal.Body className="px-md-5">
        <form onSubmit={handleSubmit} className="form-login n">
        <div className="mb-4">
            <label className="form-label">Regular Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleRegularImageChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Discount Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleDiscontImageChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Ads Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleAdsImageChange}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Setting Ads Image</label>
            <input
              type="file"
              className="form-control"
              onChange={handleSettingAdsImageChange}
            />
          </div>

          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn bg-button py-3 text-white"
              style={buttonWidth}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};


export default AddBanner