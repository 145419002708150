import React, { useEffect, useState } from "react";
import Sidebars from "../../components/sidebars/Sidebars";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import viewLogo from "../../assets/view.svg";
import ViewTransaction from "./viewTransaction/ViewTransaction";
import { useGetTransactionsQuery } from "../../Services/OrderApi";
import ReactPaginate from "react-paginate";

const ManageTransactions = () => {
  const { data } = useGetTransactionsQuery();
  const [showViewModal, setShowViewModal] = useState(false);
  const [transactionsList, setTransactionsList] = useState([]);
  const [transactionId, setTransactionId] = useState("");
  const [paginated, setPaginated] = useState([]);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");

  const viewShowHandler = (id) => {
    setShowViewModal(true);
    setTransactionId(id);
  };

  useEffect(() => {
    if (data) {
      let filterData = data.data.filter((data) => data.transaction_id != null);
      setTransactionsList(filterData);
      setPageCount(filterData?.length / itemsPerPage);
    }
  }, [data]);

  const handleClose = () => {
    setShowViewModal(false);
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
    const newOffset = e.selected * itemsPerPage;
    setOffset(newOffset);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
    setOffset(0);
    setPage(0);
  };

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = [];
    if (search?.length > 0) {
      let searchArr = transactionsList.filter((item) => {
        const amount = item?.amount;
        if (!isNaN(search)) {
          return amount?.toString().includes(search.toString());
        } else {
          return (
            item?.retailer?.fullName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.user?.fullName?.toLowerCase().includes(search.toLowerCase())
          );
        }
      });

      setPageCount(Math.ceil(searchArr?.length / itemsPerPage));
      paginated = searchArr?.slice(offset, endOffset);
    } else {
      setPageCount(Math.ceil(transactionsList?.length / itemsPerPage));
      paginated = transactionsList?.slice(offset, endOffset);
    }
    setPaginated(paginated);
  };

  useEffect(() => {
    paginationHandler();
  }, [transactionsList, offset, search]);

  return (
    <>
      <Sidebars />
      <Header />
      <main id="main" className="main mt-0 h-100 min-vh-80">
        <div className="pagetitle">
          <h1>Manage Transaction</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li className="breadcrumb-item active">Manage Transaction</li>
            </ol>
          </nav>
        </div>
        {showViewModal && (
          <ViewTransaction
            showViewModal={showViewModal}
            handleClose={handleClose}
            transactionId={transactionId}
          />
        )}
        <div className="container-fluid mt-4 pt-2">
          <div className="row">
            <div className="col-md-12 px-0">
              <h6 className="text-color fw-bolder mb-3">Transaction Lists</h6>

              <div className="clearfix mb-4 mt-2">
                <div className="float-start">
                  <div className="search-bar mt-1">
                    <form className="d-flex" role="search">
                      <div className="border-rounded-15">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control border-0 bg-none"
                            placeholder="Search..."
                            onChange={handleSearch}
                          />
                          <span className="input-group-text bg-none border-0">
                            <i className="b- bi-search"></i>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="card border-0 table-card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0 align-middle common-table ">
                      <thead>
                        <tr>
                          <th scope="col">S no.</th>
                          <th scope="col">Transaction ID</th>
                          <th scope="col">Retailer</th>
                          <th scope="col">Type</th>
                          <th scope="col">Amount</th>
                          <th scope="col">User</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginated &&
                          paginated.map((transaction, index) => (
                            <tr>
                              <th scope="row">{index + 1}</th>
                              <td>#{transaction.transaction_id}</td>
                              <td>{transaction?.retailer?.fullName}</td>
                              <td>{transaction?.type}</td>
                              <td>{transaction.amount}</td>
                              <td>{transaction.user.fullName}</td>
                              <td>{transaction.createdAt.split("T")[0]}</td>
                              <td>
                                <button
                                  className="btn btn-view  me-2 action-wh"
                                  onClick={() =>
                                    viewShowHandler(transaction._id)
                                  }
                                >
                                  <img
                                    src={viewLogo}
                                    alt="image"
                                    className="img-fluid action-image"
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {paginated?.length == 0 && <span className="no-data my-4 fw-bold"><p>No Data Found</p></span>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {transactionsList?.length > 10 && (
        <ReactPaginate
          breakLabel="..."
          breakClassName={"break"}
          nextLabel="next >"
          containerClassName={"pagination"}
          activeClassName={"active"}
          onPageChange={handlePageClick}
          forcePage={page}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
        />
      )}
      <Footer />
    </>
  );
};
export default ManageTransactions;
