import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebars from '../../../components/sidebars/Sidebars';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { useFaqPostMutation } from "../../../Services/FaqApi";
import { toast } from "react-toastify";

const AddFaq = () => {
    const [addFaqPost] = useFaqPostMutation()

    const [inputField, setInputField] = useState({
        question: "",
        answer: "",
    });

    const submitHandler = (e) => {
        e.preventDefault();
        addFaqPost(inputField)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                setInputField({
                    question: "",
                    answer: "",
                })
            })
            .catch((err) => {
                toast.error(err?.data?.message);
            });
    }

    const inputChangeHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });

    }

    return (
        <>
            <Sidebars />
            <Header />
            <main id="main" className="main mt-0 h-100 min-vh-80">
                <div className="pagetitle">
                    <Link to={'/faq'} className="text-black d-flex align-items-center mb-4"><i className="bi bi-arrow-left-short fs-4"></i>Back</Link>
                    <h1>Add Faq</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="">Home</a></li>
                            <li className="breadcrumb-item"><a href="">Faq's</a></li>
                            <li className="breadcrumb-item active">Add faq</li>
                        </ol>
                    </nav>
                </div>


                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            <div className="card border-0 table-card">
                                <div className="card-body p-4">
                                    <form className="form-login n">

                                        <div className="mb-4">
                                            <label className="form-label">Question</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Question"
                                                name="question"
                                                value={inputField.question}
                                                onChange={inputChangeHandler}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label">Answer</label>
                                            <textarea id="" cols="30" rows="10"
                                                className="form-control" placeholder="Enter Answer"
                                                name="answer"
                                                value={inputField.answer}
                                                onChange={inputChangeHandler}
                                            ></textarea>
                                        </div>
                                        <button type="submit"
                                            onClick={submitHandler}
                                            className="btn bg-button py-33 text-white">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer />
        </>
    )
}

export default AddFaq