import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import logoColor from "../../assets/logo-color.svg";
import { useForgotPasswordPostMutation } from "../../Services/ForgotPasswordApi"
import { toast } from "react-toastify";


const ForgotPassword = () => {
   const navigate = useNavigate();
   const [forgotPass] = useForgotPasswordPostMutation();
   const [email, setEmail] = useState('')

   const submitHandler = (e) => {
      e.preventDefault();

        if(!email){
         return toast.error("Please enter email address")
        }

      let userData = {
         email: email
      }
      forgotPass(userData)
         .unwrap()
         .then((res) => {
            localStorage.setItem("emailAdmin", res?.data?.email);
            toast.success(res?.message);
            navigate('/otp-verify')
         })
         .catch((err) => {
            toast.error(err?.data?.message);
         });
   }
   return (
      <div className="bg-fruit">
         <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100 h-100">
               <div className="col-md-8 col-lg-6 col-xl-5">
                  <div className="card border-0">
                     <div className="card-body p-md-5 p-4">
                        <div className="">
                           <img src={logoColor} alt="logo" className="img-fluid mb-4 mx-auto d-block" width="220" />
                           <h6 className="text-uppercase fw-bolder mb-1 pt-4 text-color">Forgot Password?</h6>
                           <p className="mb-4 small">Enter your email address and we'll send you an verification code to your email address.
                           </p>
                        </div>
                        <form action="" className="form-login n">
                           <div className="mb-4">
                              <label for="email" className="form-label small fw-semibold text-black">Email address</label>
                              <input type="email"
                                 className="form-control"
                                 placeholder="Enter Email Address"
                                 aria-label="email" aria-describedby="email"
                                 value={email}
                                 onChange={(e) => setEmail(e.target.value)}
                              />
                           </div>
                           <button
                              type="submit"
                              onClick={submitHandler}
                              className="btn bg-button w-100 text-white py-3 mt-3">Submit</button>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default ForgotPassword