import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useGetTransactionDetailQuery } from "../../../Services/OrderApi";

const buttonWidth = {
  width: "100%",
};

const ViewTransaction = (props) => {
  const { showViewModal, handleClose, transactionId } = props;
  const { data } = useGetTransactionDetailQuery(transactionId);
  const [transactionData, setTransactionData] = useState("");

  useEffect(() => {
    if (data) {
      console.log("transa====>", data);
      setTransactionData(data.data);
    }
  }, [data]);

  return (
    <Modal show={showViewModal} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h4">View Transaction</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="px-md-5">
        <form className="form-login n">
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Transaction ID</label>
              <p className="data-p-tag">#{transactionData?.transaction_id}</p>
            </div>
            <div className="col mb-4">
              <label className="form-label">Retailer</label>
              <p className="data-p-tag">
                {transactionData?.retailer?.fullName}
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Type</label>
              <p className="data-p-tag">{transactionData?.type}</p>
            </div>
            <div className="col mb-4">
              <label className="form-label">Amount</label>
              <p className="data-p-tag">${transactionData?.amount}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">User</label>
              <p className="data-p-tag">{transactionData?.user?.fullName}</p>
            </div>
            <div className="col mb-4">
              <label className="form-label">Status</label>
              <p className="data-p-tag">
                {transactionData?.is_paid ? "paid" : "unpaid"}
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Created Date</label>
              <p className="data-p-tag">
                {transactionData?.createdAt?.split("T")[0]}
              </p>
            </div>
          </div>

          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn bg-button py-3 text-white"
              style={buttonWidth}
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTransaction;
