import React, { useEffect, useState } from 'react'
import Sidebars from '../../components/sidebars/Sidebars'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { useGetSupportListQuery, useDeleteSupportMutation } from "../../Services/AboutUsApi"
import ReactPaginate from "react-paginate"
import ViewUserSupport from './ViewUserSupport/ViewUserSupport'
import DeleteSupport from './deleteSupport/DeleteSupport'
import { toast } from "react-toastify";
import LoadingAction from "../../components/Loader";

const UserSupport = () => {
    const { data, refetch } = useGetSupportListQuery()
    const [supportData, setSupportData] = useState([]);
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')
    const [supportId, setSupportId] = useState('')
    const [showViewModal, setShowViewModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteSupport] = useDeleteSupportMutation()
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (data) {
            let dataFilter = data.data.filter((item) => item?.user != null)
            setSupportData(dataFilter)
            setPageCount(dataFilter?.length / itemsPerPage)
        }
    }, [data])


    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }

    const viewHandler = (id) => {
        setShowViewModal(true)
        setSupportId(id)
    }
    const deleteHandler = (id) => {
        setSupportId(id)
        setShowDeleteModal(true)
    }

    const handleClose = () => {
        setShowDeleteModal(false)
        setShowViewModal(false)
    }

    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search?.length > 0) {
            let searchArr = supportData.filter((item) => item?.email?.toLowerCase().includes(search.toLowerCase()) || item?.user?.fullName?.toLowerCase().includes(search.toLowerCase()))
            setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
            paginated = searchArr?.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(supportData?.length / itemsPerPage))
            paginated = supportData?.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }


    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }

    useEffect(() => {
        paginationHandler()
    }, [supportData, offset, search])

    const handleDelete = () => {
        deleteSupport(supportId)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                setShowLoader(false)
                setSupportData(
                    supportData.filter((support) => support.id !== supportId)
                );
                refetch();

            })
            .catch((err) => {
                setShowLoader(false)
                toast.error(err?.data?.message);
            });
    }


    return (
        <>
            <Sidebars />
            <Header />
            <main id="main" className="main mt-0 h-100 min-vh-80">
                <div className="pagetitle">
                    <h1>User Support</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="">Home</a></li>
                            <li className="breadcrumb-item active">User Support</li>
                        </ol>
                    </nav>
                </div>
                {showLoader && <LoadingAction />}
                <div class="container-fluid mt-4 pt-2">
                    <div class="row">
                        <div class="col-md-12 px-0">
                            <h6 class="text-color fw-bolder mb-3">Support Lists</h6>
                            <div class="clearfix mb-4 mt-2">
                                <div class="float-start">
                                    <div class="search-bar mt-1">
                                        <form class="d-flex" role="search">
                                            <div class="border-rounded-15">
                                                <div class="input-group">
                                                    <input type="text" class="form-control border-0 bg-none" placeholder="Search..." onChange={handleSearch} />
                                                    <span class="input-group-text bg-none border-0">
                                                        <i class="b- bi-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="float-end">

                                </div>
                            </div>
                            {showViewModal && <ViewUserSupport showViewModal={showViewModal} handleClose={handleClose} supportId={supportId} />}
                            {showDeleteModal && <DeleteSupport showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDelete} />}
                            <div class="col-md-12">
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                        <div class="card border-0 table-card">
                                            <div class="card-body">

                                                <div class="table-responsive">
                                                    <table class="table table-striped mb-0 align-middle common-table ">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S no.</th>
                                                                <th scope="col">User Name</th>
                                                                <th scope="col">Email</th>
                                                                <th scope="col">Subject</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {paginated && paginated?.map((support, index) => (
                                                                <tr key={index}>
                                                                    <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>
                                                                    <td>{support?.user?.fullName}</td>
                                                                    <td>{support.email}</td>
                                                                    <td>{support.subject}</td>
                                                                    <td>{support.description.length > 10 ? support.description.substring(0, 10) + '...' : support.description}</td>
                                                                    <td>
                                                                        <button class="btn btn-view  me-2 action-wh" onClick={() => viewHandler(support._id)}><img src="assets/img/view.svg" alt="image" class="img-fluid action-image" /></button>
                                                                        <button class="btn btn-delete  me-2 action-wh" onClick={() => deleteHandler(support._id)}><img src="assets/img/delete.svg" alt="image" class="img-fluid action-image" /></button>

                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {supportData?.length > 10 &&
                <ReactPaginate
                    breakLabel="..."
                    breakClassName={'break'}
                    nextLabel="next >"
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    onPageChange={handlePageClick}
                    forcePage={page}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="< previous"

                />
            }
            <Footer />
        </>
    )
}
export default UserSupport