import React,{useEffect, useState} from "react";
import EditSellerList from "./editSellerList/EditSellerList";
import ViewSellerList from "./viewSellerList/ViewSellerList";
import DeleteSellerList from "./deleteSellerList/DeleteSellerList";
import { useDeleteCustomerSellerMutation } from '../../../Services/ManageCustomerRetailerApi';
import { toast } from "react-toastify";
import LoadingAction from "../../../components/Loader";

const SellerList = (props) => {
  const { retailerList } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [retailerId, setRetailerID] = useState('')
  const [showLoader, setShowLoader] = useState(false);
  const [deleteRetailer] = useDeleteCustomerSellerMutation();
  const [sellerList,setSellerList] = useState([])

  const handleClose = () => {
    setShowEdit(false);
    setShowDeleteModal(false);
    setShowViewModal(false);
  };

  const editModalHandler = (e, id) => {
    e.preventDefault();
    setShowEdit(true)
    setRetailerID(id)
  }
  const viewModalHandler = (e, id) => {
    e.preventDefault();
    setShowViewModal(true)
    setRetailerID(id)
  }
  const deleteModalHandler = (e, id) => {
    e.preventDefault();
    setShowDeleteModal(true);
    setRetailerID(id)
  }
  const handleDeleteRetailer = () => {
    setShowLoader(true)
    deleteRetailer(retailerId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setShowLoader(false)
        //refetch();
      })
      .catch((err) => {
        setShowLoader(false)
        toast.error(err?.data?.message);
      });
  };

  useEffect(()=>{
   let sellers = retailerList?.slice(0,3)
   setSellerList(sellers)
  },[retailerList])

  return (
    <div class="col-md-6 text-nowrap">
      <h6 class="text-color fw-bolder mb-3">Seller Lists</h6>
     {showEdit && (<EditSellerList showEdit={showEdit} handleClose={handleClose} retailerId={retailerId}/>)} 
     {showViewModal && (<ViewSellerList showViewModal={showViewModal} handleClose={handleClose} retailerId={retailerId}/>)}
      {showDeleteModal && (<DeleteSellerList showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteRetailer}/>)} 
      <div class="card border-0 table-card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped mb-0 align-middle common-table ">
              <thead>
                <tr>
                  <th scope="col">S no.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Registration Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {showLoader && <LoadingAction />}
              <tbody>
                {sellerList && sellerList?.map((customer, index) => (

                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{customer?.fullName}</td>
                    <td>{customer?.email}</td>
                    <td>{customer?.mobileNumber}</td>
                    <td>{customer?.createdAt?.substring(0, 10)}</td>
                    <td>
                      {" "}
                      <span className="status">Inactive</span>
                    </td>
                    <td>
                      {/* <button className="btn btn-deactivate me-2 action-wh">
                        <img
                          src="assets/img/deactivate.svg"
                          alt="image"
                          className="img-fluid action-image"
                        />
                      </button> */}
                      <button className="btn btn-edit me-2 action-wh">
                        <img
                          src="assets/img/edit.svg"
                          alt="image"
                          className="img-fluid action-image"
                          onClick={(e)=>editModalHandler(e,customer?._id)}
                        />
                      </button>
                      <button className="btn btn-view  me-2 action-wh">
                        <img
                          src="assets/img/view.svg"
                          alt="image"
                          className="img-fluid action-image"
                          onClick={(e)=>viewModalHandler(e,customer?._id)}
                        />
                      </button>
                      <button className="btn btn-delete  me-2 action-wh">
                        <img
                          src="assets/img/delete.svg"
                          alt="image"
                          className="img-fluid action-image"
                          onClick={(e)=>deleteModalHandler(e,customer?._id)}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellerList;
