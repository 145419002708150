import React, { useEffect, useState } from "react";
import Sidebars from "../../components/sidebars/Sidebars";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import deleteLogo from "../../assets/delete.svg";
import editLogo from "../../assets/edit.svg";
 import DeleteBanner from "./deleteBanner/DeleteBanner";
 import EditBanner from "./editBanner/EditBanner";
import AddBanner from "./addBanner/AddBanner";
import {
  useGetBannerListQuery,
  useDeleteBannerMutation,
} from "../../Services/BannerApi";
import { GetUrl } from "../../config/GetUrl";
import { toast } from "react-toastify";
import LoadingAction from "../../components/Loader";

const Banner = () => {
  const [show, setShow] = useState(false);
  const { data: retrieveCharity, isLoading, refetch } = useGetBannerListQuery();
  const [deleteCharity] = useDeleteBannerMutation();
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [banners, setBanners] = useState([""]);
  const [charityId, setCharityId] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(()=>{
    refetch()
  },[,refetch])

  useEffect(() => {
    setBanners(retrieveCharity?.data);
  }, [retrieveCharity]);
  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowDeleteModal(false);
  };
  const handleAddCharity = (newCategory) => {
    setBanners([...banners, newCategory]);
  };
  const editeHandlerShow = (e, id) => {
    e.preventDefault();
    setShowEdit(true);
    setCharityId(id);
  };
  const deleteShowHandler = (e, id) => {
    e.preventDefault();
    setShowDeleteModal(true);
    setCharityId(id);
  };

  const handleDeleteBanner = () => {
    setShowLoader(true);
    deleteCharity(charityId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setShowLoader(false);
        setBanners(banners.filter((category) => category.id !== charityId));
        refetch();
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };

  return (
    <>
      <Sidebars />
      <Header />
      <main id="main" class="main mt-0 h-100 min-vh-80">
        <div class="pagetitle">
          <h1>Manage Banner</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li class="breadcrumb-item active">Manage Banner</li>
            </ol>
          </nav>
        </div>
        {show && (
          <AddBanner
            show={show}
            handleClose={handleClose}
            onCharityAdded={handleAddCharity}
          />
        )}
        {showEdit && (
          <EditBanner
            showEdit={showEdit}
            handleClose={handleClose}
            charityId={charityId}
          />
        )}
        {showDeleteModal && (
          <DeleteBanner
            showDeleteModal={showDeleteModal}
            handleClose={handleClose}
            handleDelete={handleDeleteBanner}
          />
        )}
        {showLoader && <LoadingAction />}
        <div class="container-fluid mt-4 pt-2">
          <div class="row">
            <div class="col-md-12 px-0">
              {/* <h6 class="text-color fw-bolder mb-3">Charity Lists</h6> */}

              <div class="clearfix mb-4 mt-2">

                <div class="float-end">
                  <button
                    class="btn btn-main me-2"
                    onClick={(e) => setShow(true)}
                  >
                    <i class="bi bi-plus-lg me-2"></i>Add Banner
                  </button>
                </div>
              </div>

              <div class="card border-0 table-card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-striped mb-0 align-middle common-table ">
                      <thead>
                        <tr>
                          <th scope="col">S no.</th>
                          <th scope="col">Regular Image</th>
                          <th scope="col">Discount Image</th>
                          <th scope="col">Ads Image</th>
                          <th scope="col">Setting Ads Image</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {banners && banners?.map((charity, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td><img
                              src={GetUrl?.IMAGE_URL + charity?.regular_bg_image}
                              alt="Profile"
                              width="40"
                              height="40"
                              className="rounded-circle"
                            /></td>
                            <td>
                              <img
                                src={GetUrl?.IMAGE_URL + charity?.discount_bg_image}
                                alt="Profile"
                                width="40"
                                height="40"
                                className="rounded-circle"
                              />
                            </td>
                            <td>
                              <img
                                src={GetUrl?.IMAGE_URL + charity?.ads_image}
                                alt="Profile"
                                width="40"
                                height="40"
                                className="rounded-circle"
                              />
                            </td>
                            <td>
                              <img
                                src={GetUrl?.IMAGE_URL + charity?.setting_ads_image}
                                alt="Profile"
                                width="40"
                                height="40"
                                className="rounded-circle"
                              />
                            </td>
                            <td>
                              <button
                                class="btn btn-edit me-2 action-wh"
                                onClick={(e) =>
                                  editeHandlerShow(e, charity?._id)
                                }
                              >
                                <img
                                  src={editLogo}
                                  alt="image"
                                  class="img-fluid action-image"
                                />
                              </button>
                              <button
                                class="btn btn-delete  me-2 action-wh"
                                onClick={(e) =>
                                  deleteShowHandler(e, charity?._id)
                                }
                              >
                                <img
                                  src={deleteLogo}
                                  alt="image"
                                  class="img-fluid action-image"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                        {isLoading && (
                          <div className="load-body">Loading...</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};


export default Banner