import React, { useEffect, useState } from "react";
import Sidebars from "../../components/sidebars/Sidebars";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import deleteLogo from "../../assets/delete.svg";
import editLogo from "../../assets/edit.svg";
import DeleteCharity from "./deleteCharity/DeleteCharity";
import EditCharity from "./editCharity/EditCharity";
import AddCharity from "./addCharity/AddCharity";
import ReactPaginate from "react-paginate"
import {
  useGetCharityQuery,
  useDeleteCharityMutation,
} from "../../Services/CharityApi";
import { GetUrl } from "../../config/GetUrl";
import { toast } from "react-toastify";
import LoadingAction from "../../components/Loader";

const Charity = () => {
  const [show, setShow] = useState(false);
  const { data: retrieveCharity, isLoading, refetch } = useGetCharityQuery();
  const [deleteCharity] = useDeleteCharityMutation();
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [charities, setCharities] = useState([""]);
  const [charityId, setCharityId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')



  useEffect(() => {
    setCharities(retrieveCharity?.data);
  }, [retrieveCharity]);
  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowDeleteModal(false);
  };
  const handleAddCharity = (newCategory) => {
    setCharities([...charities, newCategory]);
  };
  const editeHandlerShow = (e, id) => {
    e.preventDefault();
    setShowEdit(true);
    setCharityId(id);
  };
  const deleteShowHandler = (e, id) => {
    e.preventDefault();
    setShowDeleteModal(true);
    setCharityId(id);
  };

  const handleDeleteCharity = () => {
    setShowLoader(true);
    deleteCharity(charityId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setShowLoader(false);
        setCharities(charities.filter((category) => category.id !== charityId));
        refetch();
      })
      .catch((err) => {
        setShowLoader(false);
        toast.error(err?.data?.message);
      });
  };


  const handleSearch = (e) => {
    setSearch(e.target.value)
    setOffset(0)
    setPage(0)

}

  const paginationHandler = () => {
    const endOffset = offset + itemsPerPage;
    let paginated = []
    if (search?.length > 0) {
        let searchArr = charities.filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
        setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
        paginated = searchArr?.slice(offset, endOffset)
    }
    else {
        setPageCount(Math.ceil(charities?.length / itemsPerPage))
        paginated = charities?.slice(offset, endOffset)
    }
    setPaginated(paginated)
}


  const handlePageClick = (e) => {
    setPage(e.selected)
    const newOffset = (e.selected * itemsPerPage);
    setOffset(newOffset)
}

useEffect(() => {
  paginationHandler()
}, [charities, offset, search])

  return (
    <>
      <Sidebars />
      <Header />
      <main id="main" class="main mt-0 h-100 min-vh-80">
        <div class="pagetitle">
          <h1>Manage Charity</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="">Home</a>
              </li>
              <li class="breadcrumb-item active">Manage Charity</li>
            </ol>
          </nav>
        </div>
        {show && (
          <AddCharity
            show={show}
            handleClose={handleClose}
            onCharityAdded={handleAddCharity}
          />
        )}
        {showEdit && (
          <EditCharity
            showEdit={showEdit}
            handleClose={handleClose}
            charityId={charityId}
          />
        )}
        {showDeleteModal && (
          <DeleteCharity
            showDeleteModal={showDeleteModal}
            handleClose={handleClose}
            handleDelete={handleDeleteCharity}
          />
        )}

        <div class="container-fluid mt-4 pt-2">
          <div class="row">
            <div class="col-md-12 px-0">
              <h6 class="text-color fw-bolder mb-3">Charity Lists</h6>
              {showLoader && <LoadingAction />}
              <div class="clearfix mb-4 mt-2">
                <div class="float-start">
                  <div class="search-bar mt-1">
                    <form class="d-flex" role="search">
                      <div class="border-rounded-15">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control border-0 bg-none"
                            placeholder="Search..."
                            onChange={handleSearch}
                          />
                          <span class="input-group-text bg-none border-0">
                            <i class="b- bi-search"></i>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="float-end">
                  <button
                    class="btn btn-main me-2"
                    onClick={(e) => setShow(true)}
                  >
                    <i class="bi bi-plus-lg me-2"></i>Add Charity
                  </button>
                </div>
              </div>

              <div class="card border-0 table-card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-striped mb-0 align-middle common-table ">
                      <thead>
                        <tr>
                          <th scope="col">S no.</th>
                          <th scope="col">Name</th>
                          <th scope="col">Image</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginated && paginated?.map((charity, index) => (
                          <tr key={index}>
                            <th scope="row">{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</th>
                            <td>{charity?.name}</td>
                            <td>
                              <img
                                src={GetUrl?.IMAGE_URL + charity?.images}
                                alt="Profile"
                                width="40"
                                height="40"
                                className="rounded-circle"
                              />
                            </td>
                            <td>
                              <button
                                class="btn btn-edit me-2 action-wh"
                                onClick={(e) =>
                                  editeHandlerShow(e, charity?._id)
                                }
                              >
                                <img
                                  src={editLogo}
                                  alt="image"
                                  class="img-fluid action-image"
                                />
                              </button>
                              <button
                                class="btn btn-delete  me-2 action-wh"
                                onClick={(e) =>
                                  deleteShowHandler(e, charity?._id)
                                }
                              >
                                <img
                                  src={deleteLogo}
                                  alt="image"
                                  class="img-fluid action-image"
                                />
                              </button>
                            </td>
                          </tr>
                        ))}
                        {isLoading && (
                          <div className="load-body">Loading...</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {charities?.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
      <Footer />
    </>
  );
};
export default Charity;
