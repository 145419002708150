import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const promoCodeApi = createApi({
  reducerPath: "promoCodeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    CreatePromoCodePost: builder.mutation({
      query: (data) => ({
        url: "/admin/create-promo-code",
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetPromoCodeList: builder.query({
      query: ({searchCode}) => ({
        url: `/admin/get-promo-code-list?searchCode=${searchCode}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetSinglePromoCode: builder.query({
      query: (id) => ({
        url: `/admin/get-single-promo-code/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    EditPromoCode: builder.mutation({
      query: ({ id, data }) => ({
        url: `/admin/edit-promo-code/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    DeletePromoCode: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-promo-code/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
  }),
});

export const {
  useCreatePromoCodePostMutation,
  useGetPromoCodeListQuery,
  useGetSinglePromoCodeQuery,
  useEditPromoCodeMutation,
  useDeletePromoCodeMutation,
} = promoCodeApi;
