import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    GetOrders: builder.query({
      query: () => ({
        url: "/admin/get-orders",
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetOrderDetail: builder.query({
      query: (id) => ({
        url: `/admin/get-order-detail/${id}`,
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    DeleteOrder: builder.mutation({
      query: (id) => ({
        url: `/admin/delete-order/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
        },
      }),
    }),
    GetTransactions: builder.query({
        query: () => ({
          url: "/admin/get-transactions",
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        }),
      }),
      GetTransactionDetail: builder.query({
        query: (id) => ({
          url: `/admin/get-transaction-detail/${id}`,
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
          },
        }),
      }),
  }),
});

export const {
useGetOrdersQuery,
useGetOrderDetailQuery,
useDeleteOrderMutation,
useGetTransactionsQuery,
useGetTransactionDetailQuery
} = orderApi;
