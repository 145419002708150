import React from "react";

const RecentOrder = () => {
  return (
    <div class="col-md-12 mb-4">
      <h6 class="text-color fw-bolder mb-3">Recent Orders</h6>

      <div class="card border-0 table-card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped mb-0 align-middle common-table ">
              <thead>
                <tr>
                  <th scope="col">S no.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mobile Number</th>
                  <th scope="col">Registration Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>mark@gmail.com</td>
                  <td>76754345454453</td>
                  <td>12 April 2024</td>
                  <td>
                    {" "}
                    <span class="status">Inactive</span>
                  </td>
                  <td>
                    <button class="btn btn-deactivate me-2 action-wh">
                      <img
                        src="assets/img/deactivate.svg"
                        alt="image"
                        class="img-fluid action-image"
                      />
                    </button>
                    <button class="btn btn-edit me-2 action-wh">
                      <img
                        src="assets/img/edit.svg"
                        alt="image"
                        class="img-fluid action-image"
                      />
                    </button>
                    <button class="btn btn-view  me-2 action-wh">
                      <img
                        src="assets/img/view.svg"
                        alt="image"
                        class="img-fluid action-image"
                      />
                    </button>
                    <button class="btn btn-delete  me-2 action-wh">
                      <img
                        src="assets/img/delete.svg"
                        alt="image"
                        class="img-fluid action-image"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentOrder;
