import React from "react";
import orederIcon from "../../../assets/order.svg";

const TotalOrder = () => {
  return (
    <div class="col-md-4">
      <div class="card border-0 py-2 mb-4">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-9">
              <h3 class="text-color fw-bolder">0</h3>
              <h5 class="text-muted small mb-0">Total Orders</h5>
            </div>

            <div class="col-3">
              <img src={orederIcon} alt="image" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalOrder;
