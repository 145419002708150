import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
   
    LoginPost: builder.mutation({
      query: (data) => ({
        url: "/admin/login",
        method: "POST",
        body: data,
        headers: {
          "Accept": "application/json"
        }
        
      }),
    }),
    getTotalCustomer: builder.query({
    query: () => ({
      url: `/admin/get-total-customer`,
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
      },
    }),
  }),
  getTotalRetailer: builder.query({
    query: () => ({
      url: `/admin/get-total-retailer`,
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("tokenAdmin")}`,
      },
    }),
  }),
  
  }),
});

export const {
  useLoginPostMutation,
  useGetTotalCustomerQuery,
  useGetTotalRetailerQuery
} = loginApi;
