import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useGetOrderDetailQuery } from "../../../Services/OrderApi";

const buttonWidth = {
  width: "100%",
};

const ViewOrderManagement = (props) => {
  const { showViewModal, handleClose, orderId } = props;
  const { data } = useGetOrderDetailQuery(orderId);
  const [orderDetail, setOrderDetail] = useState("");

  useEffect(() => {
    console.log("data===>", data);
    if (data) {
      setOrderDetail(data.data);
    }
  }, [data]);

  return (
    <Modal show={showViewModal} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h4">View Order</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="px-md-5">
        <form className="form-login n">
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Order ID</label>
              <p className="data-p-tag">
                #
                {orderDetail.doordash_order_id
                  ? orderDetail.doordash_order_id
                  : orderDetail.order_id}
              </p>
            </div>
            <div className="col mb-4">
              <label className="form-label">Order Type</label>
              <p className="data-p-tag">
                {orderDetail?.order_type ? orderDetail?.order_type : "NIL"}
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Retailer Name</label>
              <p className="data-p-tag">{orderDetail?.retailer?.fullName}</p>
            </div>
            <div className="col mb-4">
              <label className="form-label">Retailer Email</label>
              <p className="data-p-tag">{orderDetail?.retailer?.email}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Customer Name</label>
              <p className="data-p-tag">{orderDetail?.user?.fullName}</p>
            </div>
            <div className="col mb-4">
              <label className="form-label">Customer Email</label>
              <p className="data-p-tag">{orderDetail?.user?.email}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Amount</label>
              <p className="data-p-tag">
                ${orderDetail?.transaction_id?.amount}
              </p>
            </div>
            <div className="col mb-4">
              <label className="form-label">Status</label>
              <p className="data-p-tag">{orderDetail?.status}</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col mb-4">
              <label className="form-label">Created Date</label>
              <p className="data-p-tag">
                {orderDetail?.createdAt?.split("T")[0]}
              </p>
            </div>
          </div>

          <div className="text-center mb-4">
            <button
              type="submit"
              className="btn bg-button py-3 text-white"
              style={buttonWidth}
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ViewOrderManagement;
