import { configureStore } from "@reduxjs/toolkit";
import { loginApi } from "./Services/LoginApi";
import { forgotPasswordApi } from "./Services/ForgotPasswordApi";
import { categoryApi } from "./Services/CategoryApi";
import { charityApi } from "./Services/CharityApi";
import { subCategoryApi } from "./Services/SubCategoryApi";
import { privacyPolicyApi } from "./Services/PrivacyPolicy";
import { termConditionApi } from "./Services/TermCondition";
import { aboutUsApi } from "./Services/AboutUsApi";
import { faqApi } from "./Services/FaqApi";
import { promoCodeApi } from "./Services/PromoCodeApi";
import { manageCustomerRetailerApi } from "./Services/ManageCustomerRetailerApi";
import { bannerApi } from "./Services/BannerApi";
import { orderApi } from "./Services/OrderApi";

export const store = configureStore({
  reducer: {
    [loginApi.reducerPath]: loginApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [subCategoryApi.reducerPath]: subCategoryApi.reducer,
    [charityApi.reducerPath]: charityApi.reducer,
    [privacyPolicyApi.reducerPath]: privacyPolicyApi.reducer,
    [termConditionApi.reducerPath]: termConditionApi.reducer,
    [aboutUsApi.reducerPath]: aboutUsApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [manageCustomerRetailerApi.reducerPath]: manageCustomerRetailerApi.reducer,
    [promoCodeApi.reducerPath]: promoCodeApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      loginApi.middleware,
      forgotPasswordApi.middleware,
      categoryApi.middleware,
      subCategoryApi.middleware,
      charityApi.middleware,
      privacyPolicyApi.middleware,
      termConditionApi.middleware,
      aboutUsApi.middleware,
      faqApi.middleware,
      manageCustomerRetailerApi.middleware,
      promoCodeApi.middleware,
      bannerApi.middleware,
      orderApi.middleware
    ),
});
